import React, {useState, useEffect} from 'react'
import {Link, useHistory, useParams, useLocation} from 'react-router-dom'
import {Breadcrumbs, Typography} from '@material-ui/core'
import Swal from 'sweetalert2'
import axios from '../../../helpers/request'
import './newsurvey.css'
import Cookies from 'universal-cookie'

export default function NewContact() {
  const params = useParams()
  const location = useLocation()
  const history = useHistory()
  const isEditable = location.pathname.split('/').includes('survey-edit')
  const isReadonly = location.pathname.split('/').includes('survey-view')
  const cookies = new Cookies()
  const userToken = cookies.get('cs_token')
  const [loading, setLoading] = useState(false)
  const [companyId, setUserID] = useState('')
  const [channelList, setChannelList] = useState([])
  const [channel, setChannel] = useState('')
  const [questions, setQuestions] = useState([
    {
      text: '',
      type: '10'
    }
  ])
  const [surveyData, setData] = useState({
    surveyName: '',
    timeout: 60,
    invalidResponseText: ''
  })

  // Function to handle changes in input values
  const handleInputChange = (index, event, name) => {
    const newInputs = [...questions]
    newInputs[index] = {...newInputs[index], [name]: event.target.value}
    setQuestions(newInputs)
  }

  // Function to add a new input field
  const addInputField = () => {
    setQuestions([...questions, {text: '', type: '10'}])
  }

  // Function to remove an input field
  const removeInputField = index => {
    const newInputs = [...questions]
    newInputs.splice(index, 1)
    setQuestions(newInputs)
  }

  const getChannelList = companyId => {
    axios.get(`/all-channels/${companyId}`).then(async response => {
      if (response.status === 200 && response.data) {
        console.log(response.data)
        setChannelList(response.data)
      } else {
        throw new Error(response?.error)
      }
    })
  }

  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_API_BASEURL}/userdetails`, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      })
      .then(res => {
        if (res.data.length > 0) {
          const cmpny = res.data[0].added_by ? res.data[0].added_by : res.data[0].id
          setUserID(cmpny)
          getChannelList(cmpny)
        }
      })
      .catch(err => {
        console.log('errr', err)
      })
  }, [])

  useEffect(() => {
    // getContactList()
    if ((isEditable || isReadonly) && params.id) {
      getTemplateDetail()
    }
  }, [])

  // const getContactList = () => {
  //   axios.get(`/all-survey-list`).then(async response => {
  //     if (response.status === 200 && response.data) {
  //       let templist = response.data

  //       if (currentListId !== 'all_list') {
  //         templist = templist.filter(list => {
  //           return list.listId === currentListId
  //         })
  //         setData({...surveyData, type: templist[0].type})
  //       }
  //     } else {
  //       throw new Error(response?.error)
  //     }
  //   })
  // }

  const getTemplateDetail = () => {
    axios
      .get(`/surveys/detail/${params.id}`)
      .then(res => {
        if (res.data && res.data.success && res.data.data.length) {
          setChannel(res.data.data[0].channelId)
          setData({
            surveyName: res.data.data[0].name,
            timeout: res.data.data[0].timeout,
            invalidResponseText: res.data.data[0].invalidText
          })
          setQuestions(
            res.data.data.map(({type, text}) => ({
              text,
              type
            }))
          )
        }
      })
      .catch(err => {
        console.log('errr', err)
      })
  }

  const isValidData = () => {
    const {surveyName, timeout, invalidResponseText} = surveyData
    let isValid = true
    let message = ''

    if (!questions.length) {
      isValid = false
      message = 'There should be at least 1 survey question'
    }
    questions.forEach(q => {
      if (q.text === '') {
        isValid = false
        message = 'Question text cannot be empty'
      }
    })
    if (surveyName === '') {
      isValid = false
      message = 'Please enter the survey name'
    } else if (channel === '') {
      isValid = false
      message = 'Please enter the survey name'
    } else if (invalidResponseText === '') {
      isValid = false
      message = 'Please enter invalid response message text'
    } else if (timeout < 60 || timeout > 3600) {
      isValid = false
      message = 'Timeout should be between 60 and 3600'
    }
    return {isValid, message}
  }

  const handleSubmit = () => {
    setLoading(true)
    const {isValid, message} = isValidData()
    if (isValid) {
      if (!isEditable) {
        axios
          .post('surveys', {
            ...surveyData,
            channel,
            companyId,
            questions
          })
          .then(res => {
            if (res.data && res.data.success) {
              history.goBack()
            } else {
              Swal.fire({title: 'Something went wrong adding survey!', confirmButtonColor: '#3085d6'})
            }
            setLoading(false)
            console.log(res.data)
          })
          .catch(err => {
            Swal.fire({
              title: err?.response?.data?.message || 'Something went wrong adding survey!',
              confirmButtonColor: '#3085d6'
            })
            setLoading(false)
            console.log(err)
          })
      } else {
        axios
          .put(`surveys/${params.id}`, {
            ...surveyData,
            channel,
            companyId,
            questions
          })
          .then(res => {
            if (res.data && res.data.success) {
              history.goBack()
            } else {
              Swal.fire({title: 'Something went wrong updating survey!', confirmButtonColor: '#3085d6'})
            }
            setLoading(false)
            console.log(res.data)
          })
          .catch(err => {
            Swal.fire({
              title: err?.response?.data?.message || 'Something went wrong updating survey!',
              confirmButtonColor: '#3085d6'
            })
            setLoading(false)
            console.log(err)
          })
      }
    } else {
      setLoading(false)
      Swal.fire({title: message, confirmButtonColor: '#3085d6'})
    }
  }

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb" className="mb-5">
        <Link to={`/surveys-templates/`}>Contacts</Link>
        <Typography color="text.primary">{params.id ? 'Survey' : 'New Survey'}</Typography>
      </Breadcrumbs>
      <div className="newUserItem">
        <label>Please select a channel</label>
        <select
          value={channel}
          className="popupDropdown"
          onChange={event => {
            setChannel(event.target.value)
          }}
          disabled={isReadonly}
        >
          <option value="" disabled>
            Select channel
          </option>
          {channelList
            .filter(v => ['twilio-messaging-service'].includes(v.type))
            .map(chan => (
              <option key={chan.id} value={chan.id}>
                {chan.label}
              </option>
            ))}
        </select>
      </div>
      <div className="newUserItem">
        <label>
          {'Template Name'} <span className="mandatory">*</span>
        </label>
        <input
          name="label"
          type="text"
          maxLength={50}
          onChange={e => setData({...surveyData, surveyName: e.target.value})}
          value={surveyData.surveyName}
          disabled={isReadonly}
          placeholder="Enter the name"
        />
      </div>
      <div className="newUserItem">
        <label>
          {'Survey timeout(sec)'} <span className="mandatory">*</span>
        </label>
        <input
          name="label"
          type="number"
          onChange={e => setData({...surveyData, timeout: parseInt(e.target.value)})}
          value={surveyData.timeout}
          disabled={isReadonly}
          placeholder="Enter the name"
        />
      </div>
      <div className="newUserItem">
        <label>
          {'Invalid response text'} <span className="mandatory">*</span>
        </label>
        <input
          name="label"
          type="text"
          maxLength={60}
          onChange={e => setData({...surveyData, invalidResponseText: e.target.value})}
          disabled={isReadonly}
          value={surveyData.invalidResponseText}
          placeholder="Enter the text for invalid user response"
        />
      </div>
      {questions.map((question, index) => (
        <>
          <div key={index} className="newUserItem">
            <label>
              {`Question ${index + 1}`} <span className="mandatory">*</span>
            </label>
            <input
              type="text"
              maxLength={190}
              value={question.text}
              disabled={isReadonly}
              placeholder="Enter question text"
              onChange={e => handleInputChange(index, e, 'text')}
            />
          </div>
          <div className="newUserItem">
            <label>
              Response Type <span className="mandatory">*</span>
            </label>
            <select
              name="channel"
              className="popupDropdown"
              value={question.type}
              disabled={isReadonly}
              onChange={e => handleInputChange(index, e, 'type')}
            >
              <option disabled selected value="">
                Select channel type
              </option>
              {[
                {
                  label: 'Number(1 - 5)',
                  value: '5'
                },
                {
                  label: 'Number(1 - 10)',
                  value: '10'
                }
              ].map(channel => {
                return (
                  <option key={channel.value} value={channel.value}>
                    {channel.label}
                  </option>
                )
              })}
            </select>
            {!isReadonly && (
              <button
                className="newChannelButton align-self-end"
                disabled={questions.length <= 1}
                onClick={() => removeInputField(index)}
              >
                Remove {index + 1}
              </button>
            )}
          </div>
        </>
      ))}
      <br />
      {!isReadonly && (
        <>
          <button className="newChannelButton align-self-end" onClick={addInputField} disabled={loading || questions.length >= 5}>
            Add Question
          </button>
          <div className="newUserItem">
            <button
              className="newChannelButton align-self-end"
              disabled={Object.keys(surveyData).filter(v => !v).length > 0 || loading}
              onClick={handleSubmit}
            >
              {!isEditable ? 'Create' : 'Save'} Template
            </button>
          </div>
        </>
      )}
    </div>
  )
}
