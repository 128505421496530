import './importcontacts.css'
import React, {useState, useRef, useEffect} from 'react'

import {Grid} from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'

import folderIcon from '../../../images/folder-icon.svg'
import DragDropFile from './DragDropFile'

import Swal from 'sweetalert2'

export default function BrowseFile({customFieldsObj, ...props}) {
  const browseFileRef = useRef()
  const fileReader = new FileReader()
  const [file, setFile] = useState()

  const checkMatchedHeader = header => {
    let tempHeader = header.toLowerCase().replaceAll(' ', '').replaceAll('\r', '')
    if (customFieldsObj[tempHeader]) {
      return tempHeader
    }
    return header
  }

  const csvFileToArray = string => {
    string = string.replaceAll('"', '')
    let csvRows = []
    let array = []
    let csvHeader = []
    let columnArray = []
    columnArray = string.split('\n')
    columnArray = columnArray.filter(function (el) {
      return el != null && el != ''
    })

    csvHeader = columnArray[0].split('\t')
    csvHeader = csvHeader.filter(function (el) {
      return el != null && el != ''
    })

    columnArray.shift()
    columnArray = columnArray.filter(function (el) {
      return el != null && el != ''
    })

    csvRows = columnArray.map(item => {
      let itemString = item.split('\t')
      if (itemString !== '') {
        return itemString
      }
    })
    csvHeader = csvHeader[0].split(',')

    csvHeader = csvHeader.filter(function (el) {
      return el != null
    })
    csvRows = csvRows.filter(function (el) {
      return el != null
    })

    array = csvRows.map(i => {
      i = i[0]
      let indexes = []

      for (let index = 0; index < i.length; index++) {
        if (i[index] === '"') {
          indexes.push(index)
        }
      }

      for (let j = 0; j < indexes.length; j += 2) {
        let firstI = indexes[j]
        let nextI = indexes[j + 1]
        // console.log(indexes[j]);
        let originalsubstr = i.substring(firstI, nextI)
        let newsubstr = originalsubstr.replaceAll(',', '@')
        i = i.replace(originalsubstr, newsubstr)
      }

      let values = i.split(',')

      const obj = csvHeader.reduce((object, header, index) => {
        header = checkMatchedHeader(header)
        let eleVal = values[index] ? values[index].trim() : ''

        if (eleVal !== '' && eleVal !== null) {
          object[header.trim()] = !eleVal.indexOf('"') ? eleVal.replaceAll('@', ',').replaceAll('"', '') : eleVal
        } else {
          object[header.trim()] = ''
        }
        return object
      }, {})

      return obj
    })

    array = array.filter(el => typeof el == 'object')

    // console.log("final array ", array);
    props.setBrowseFileData(array)
    props.continueHandler()
  }

  const handleOnSubmit = e => {
    e.preventDefault()

    if (file) {
      fileReader.onload = function (event) {
        const text = event.target.result
        csvFileToArray(text)
      }

      fileReader.readAsText(file)
    } else {
      Swal.fire({title: 'Select file from browse file or darg & drop csv file', confirmButtonColor: '#3085d6'})
    }
  }

  useEffect(() => {
    if (props.componentCalledSource !== 'contactList') {
      props.setUpdateModalSize(false)
    }
  }, [])

  return (
    <>
      <span className="import-contact-header">Upload your contacts</span>

      {!file && (
        <Grid container spacing={4} className="importOptions">
          <Grid
            item
            xs={12}
            md={3}
            lg={3}
            className="import-optiondiv select-method-options"
            onClick={() => browseFileRef.current.click()}
          >
            <input
              type={'file'}
              id={'csvFileInput'}
              accept={'.csv'}
              onChange={e => setFile(e.target.files[0])}
              ref={browseFileRef}
            />
            <span className="import-contact-p">
              <img src={folderIcon} className="filter-red" />
              &nbsp;&nbsp;Browse file from device
            </span>
          </Grid>
          <Grid item xs={12} md={3} lg={3} className="import-optiondiv select-method-options copypaste-option">
            <DragDropFile setFile={setFile} componentCalledSource={props.componentCalledSource} />
          </Grid>
        </Grid>
      )}

      {file && (
        <Grid container spacing={4} className="importOptions">
          <Grid item xs={12} md={6} lg={6} className="import-optiondiv file-selected">
            <span>
              <b>
                <InsertDriveFileIcon /> {file.name}
              </b>
              <CheckIcon color="success" className="file-selected-check-icon" />
            </span>
          </Grid>
        </Grid>
      )}

      <button onClick={e => handleOnSubmit(e)} className="btn btn-primary text-white br-10 importsubmit">
        Continue
      </button>

      <button onClick={() => props.handleBackClick()} className="btn btn-white-red br-10 importsubmit ml-20">
        Back
      </button>
    </>
  )
}
