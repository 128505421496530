import './custommenu.css'
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined'
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined'

const CustomMenu = props => {
  const {setOpenMenu, handleChange, openMenu, markUnmarkArchived, isArchivePage, handleUnarchive, handleDeleteConversation} =
    props

  const handleCloseDialog = () => {
    setOpenMenu(false)
  }

  return (
    <div>
      <Dialog onClose={handleCloseDialog} open={openMenu} sx>
        {/* <DialogTitle></DialogTitle> */}

        <DialogContent sx>
          <div className="custom-menu">
            {!isArchivePage && (
              <div className="item-col-select">
                <DriveFolderUploadIcon style={{height: '40px', fontSize: '20px'}} />

                <div style={{paddingLeft: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <input
                    type="file"
                    id="upload-image"
                    className="custom-file-input"
                    onChange={handleChange}
                    accept="image/*,video/mp4"
                  />
                  Browse files
                </div>
              </div>
            )}
            <div
              className="item-col-select"
              //  style={{border: 'none'}}
              onClick={() => {
                handleDeleteConversation()
                setOpenMenu(false)
              }}
            >
              <DeleteOutlineIcon style={{height: '40px', fontSize: '20px'}} />

              <div style={{paddingLeft: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                Delete conversation
              </div>
            </div>
            {isArchivePage && (
              <div
                className="item-col-select"
                //style={{border: 'none'}}
                onClick={() => {
                  handleUnarchive()
                  setOpenMenu(false)
                }}
              >
                <UnarchiveOutlinedIcon style={{height: '40px', fontSize: '20px'}} />

                <div style={{paddingLeft: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  Unarchive conversation
                </div>
              </div>
            )}
          </div>
          {!isArchivePage && (
            <div
              className="item-col-select"
              style={{border: 'none'}}
              onClick={() => {
                markUnmarkArchived()
                setOpenMenu(false)
              }}
            >
              <ArchiveOutlinedIcon style={{height: '40px', fontSize: '20px'}} />

              <div style={{paddingLeft: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                Archive conversation
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default CustomMenu
