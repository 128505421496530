import axios from '../../helpers/request'
import './superadmins.css'
import {DataGrid} from '@material-ui/data-grid'
import {DeleteOutlined, EditOutlined, VisibilityOutlined, AddOutlined} from '@material-ui/icons'
import {Link} from 'react-router-dom'
import {useState, useEffect} from 'react'
import Swal from 'sweetalert2'

export default function SuperAdmins() {
  const [data, setData] = useState([])

  const handleDelete = async (id, name) => {
    const result = await Swal.fire({
      title: `Are you sure you want to delete ${name}`,
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    })

    if (result.isConfirmed) {
      axios
        .delete(process.env.REACT_APP_API_BASEURL + '/user/' + id)
        .then(async function (response) {
          getSuperAdmins()
          await Swal.fire({
            title: 'User deleted successfully',
            confirmButtonColor: '#3085d6'
          })
        })
        .catch(async function (error) {
          await Swal.fire({
            title: error?.response?.data?.message || 'Unable to delete user',
            confirmButtonColor: '#3085d6'
          })
        })
    }
  }

  const getSuperAdmins = () => {
    axios.get('/superadmins').then(async response => {
      if (response.status === 200 && response.data) {
        setData(response.data)
      } else {
        throw new Error(response?.error)
      }
    })
  }

  useEffect(() => {
    getSuperAdmins()
  }, [])

  const columns = [
    {field: 'id', headerName: 'ID', width: 90},
    {field: 'fullName', headerName: 'Name', width: 200},
    {field: 'email', headerName: 'Email', width: 200},
    {field: 'password', headerName: 'Password', width: 200},
    {field: 'username', headerName: 'Username', width: 200},
    {
      field: 'action',
      headerName: 'Action',
      width: 400,
      renderCell: params => {
        return (
          <div className="userListActions">
            <Link to={'/user-view/' + params.row.id}>
              <button className="actionElementButton">
                <VisibilityOutlined className="actionElementIcon" />
                <span className="actionButtonText">View</span>
              </button>
            </Link>
            <Link to={'/user-edit/' + params.row.id}>
              <button className="actionElementButton">
                <EditOutlined className="actionElementIcon" />
                <span className="actionButtonText">Edit</span>
              </button>
            </Link>

            <DeleteOutlined className="userListDelete" onClick={() => handleDelete(params.row.id, params.row.fullName)} />
          </div>
        )
      }
    }
  ]

  return (
    <div className="superAdmins">
      <div className="pageSubHeader">
        <h3 className="pageTableTitle">Super Admins</h3>
        <div className="align-self-end">
          <Link to="/newUser/superadmin">
            <button className="min-w-[137px]  bg-[#f05865] cursor-pointer text-[white] h-[60px] text-lg font-normal mt-[30px] px-2.5 py-[7px] rounded-xl">
              Add
            </button>
          </Link>
        </div>
      </div>

      <DataGrid
        rows={data}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[5]}
        checkboxSelection
        disableSelectionOnClick
        autoHeight={true}
      />
    </div>
  )
}
