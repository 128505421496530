import {useState, useEffect} from 'react'
import {useLocation, useHistory, Link} from 'react-router-dom'

// import axios from "axios";
/* import or from '../images/or.svg' */
// import {templatesRows} from '../../dummyData';

import logo from '../images/ProntobyKaptea.svg'
import graphic from '../images/logingraphic2023_new.svg'

import {decryptEmail, encryptEmail, useAppState} from '../../state'
import {useGoogleLogin} from '@moeindana/google-oauth'
import axios from 'axios'

import Swal from 'sweetalert2'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Cookies from 'universal-cookie'
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone'
import MailIcon from '@mui/icons-material/Mail'
import PopupComponent from '../Popup/PopupComponent'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'

function Login() {
  const {signIn, user, isLoggedIn} = useAppState()

  const history = useHistory()
  const location = useLocation()

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [Guser, setGUser] = useState()
  const [profile, setProfile] = useState([])
  const [modelName, setModelName] = useState('')
  const [loginResponse, setLoginResponse] = useState('')
  const [loginJson, setLoginJson] = useState('')
  const [emailAddress, setEmailAddress] = useState('')
  const [otp, setOtp] = useState('')
  const [forgotPasswordPopup, setforgotPasswordPopup] = useState(false)
  const [step, setStep] = useState(0)
  const [fieldName, setFieldName] = useState('')
  const [phone, setPhoneNo] = useState('')
  const [countryCode, setCountryCode] = useState('')
  const [isChecked, setIsChecked] = useState(false)
  const [isMethodChange, setIsMethodChanges] = useState(false)
  const [eyeHide, setEyeHide] = useState(false)
  /* const [loginVerify, setLoginVerify] = useState(false) */

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(location.search)
    const params = Object.fromEntries(urlSearchParams.entries())
    if (params && params['verification-success']) {
      history.push('/login')
      Swal.fire({
        title: 'Thank you for verifying your mail, you can now login',
        confirmButtonColor: '#3085d6'
      })
    }
  }, [])

  const cookies = new Cookies()

  const openForgotPasswordPopup = event => {
    event.preventDefault()
    setforgotPasswordPopup(true)
  }

  const closeForgotPasswordPopup = () => {
    setforgotPasswordPopup(false)
    setStep(0)
    setEmailAddress('')
    setOtp('')
    setPhoneNo('')
    setFieldName('')
    setModelName('')
  }

  const verifyEmail = (email, phone, fieldName, modelName) => {
    return fetch(process.env.REACT_APP_API_BASEURL + '/verify-email', {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        email: email,
        phone: phone,
        type: fieldName,
        modelName: modelName
      })
    })
  }

  /**
   *
   * @param {*} email
   * @param {*} phone
   * @param {*} otp
   * @param {*} fieldName
   * @returns
   */
  const verifyOTP = (email, phone, otp, fieldName) => {
    return fetch(process.env.REACT_APP_API_BASEURL + '/verify-otp', {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        email: email,
        otp: otp,
        phone: phone,
        type: fieldName
      })
    })
  }

  const goToNextStep = () => {
    // this function is for reset password
    // resetPassword param is for maintain condition based template while verifyEmail
    verifyEmail(emailAddress, '', '', 'resetPassword').then(async response => {
      const jsonResponse = await response.json()
      if (response.ok) {
        setStep(1)
      } else {
        if (jsonResponse?.message) {
          Swal.fire({
            title: jsonResponse.message,
            confirmButtonColor: '#3085d6'
          })
        }
        throw new Error(response?.message)
      }
    })
  }

  const verifyOtp = () => {
    // Below condition is for Two-factor authentication with email or phone number
    const type = fieldName === 'phoneVerify' ? 'phone' : 'email'
    if (otp) {
      verifyOTP(emailAddress || username, phone, otp, type).then(async response => {
        const jsonResponse = await response.json()

        if (response.ok && jsonResponse?.valid === true) {
          closeForgotPasswordPopup()

          // This condition for check that verify-otp is for reset password or login.
          if (modelName === '') {
            history.push({
              pathname: '/passwordreset',
              state: {token: jsonResponse.token}
            })
          } else {
            const IsTrue = signIn(loginResponse, loginJson, jsonResponse)
            IsTrue && history.replace(location?.state?.from || {pathname: `/`})
          }
        } else {
          if (jsonResponse?.message) {
            Swal.fire({
              title: jsonResponse.message,
              confirmButtonColor: '#3085d6'
            })
          } else if (jsonResponse?.valid === false) {
            Swal.fire({
              title: 'Invalid otp please try again.',
              confirmButtonColor: '#3085d6'
            })
          }
          /* throw new Error(response?.message) */
        }
      })
    } else {
      Swal.fire({
        title: `Please enter one time passcode we've sent you on you mail`,
        confirmButtonColor: '#3085d6'
      })
    }
  }

  const loginAPI = (username, phone = '', password) => {
    return fetch(process.env.REACT_APP_API_BASEURL + '/login', {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        username: username,
        password: password,
        phone: phone
      })
    })
  }
  const getSMSOtp = async () => {
    const checkPhone = phone.replace(countryCode, '')

    if (phone !== '' && checkPhone !== '') {
      verifyEmail('', phone, fieldName, 'login').then(async response => {
        const jsonResponse = await response.json()

        if (response.ok) {
          setFieldName('phoneVerify')
        } else {
          if (jsonResponse?.message) {
            Swal.fire({
              title: jsonResponse.message,
              confirmButtonColor: '#3085d6'
            })
          }
          //throw new Error(response?.message);
        }
      })
    } else {
      Swal.fire({
        title: 'Please enter you phone number',
        confirmButtonColor: '#3085d6'
      })
    }
  }

  const tryOtherMethodWithPhone = async () => {
    const checkPhone = phone.replace(countryCode, '')

    if (phone !== '' && checkPhone !== '') {
      verifyEmail('', phone, 'phone', 'login').then(async response => {
        const jsonResponse = await response.json()

        if (response.ok) {
          setFieldName('phoneVerify')
        } else {
          if (jsonResponse?.message) {
            Swal.fire({
              title: jsonResponse.message,
              confirmButtonColor: '#3085d6'
            })
          }
          //throw new Error(response?.message);
        }
      })
    } else {
      Swal.fire({
        title: 'Please enter you phone number',
        confirmButtonColor: '#3085d6'
      })
    }
  }

  /**
   * This function is used for login user
   * @param {*} event
   */
  const login = event => {
    event.preventDefault()
    loginAPI(username, phone, password).then(async response => {
      const LoginJsonResponse = await response.json()
      setLoginResponse(response)
      setLoginJson(LoginJsonResponse)
      if (response.ok && process.env.REACT_APP_DEVELOPMENT_ENV) {
        const IsTrue = signIn(response, LoginJsonResponse)
        IsTrue && history.replace(location?.state?.from || {pathname: `/`})
        return
      }
      if (response.ok) {
        if (LoginJsonResponse?.isPhone === true) {
          setFieldName('phone')
        } else {
          setEmailAddress(username)
          setFieldName('email')
          verifyEmail(username, '', 'email', 'login').then(async response => {
            const jsonResponse = await response.json()

            if (response.ok) {
              /* setEmailAddress(username);
                setLoginVerify(true);
                setModelName("login"); */
              setFieldName('email')
            } else {
              if (jsonResponse?.message) {
                Swal.fire({
                  title: jsonResponse.message,
                  confirmButtonColor: '#3085d6'
                })
              }
              throw new Error(response?.message)
            }
          })
        }
      } else {
        Swal.fire({
          title: LoginJsonResponse.message,
          confirmButtonColor: '#3085d6'
        })
      }

      setModelName('login')
    })
  }

  const optionMethodMailVerify = async () => {
    verifyEmail(username, '', 'email', 'login').then(async response => {
      const jsonResponse = await response.json()

      if (response.ok) {
        /* setEmailAddress(username);
          setLoginVerify(true);
          setModelName("login"); */
        setFieldName('email')
        setIsMethodChanges(false)
      } else {
        if (jsonResponse?.message) {
          Swal.fire({
            title: jsonResponse.message,
            confirmButtonColor: '#3085d6'
          })
        }
        throw new Error(response?.message)
      }
    })
  }
  const getRememberMe = cookies.get('IsRememberMe')
  const rememberMeFunction = e => {
    if (username || password) {
      setIsChecked(e.target.checked)
      if (e.target.checked === true) {
        cookies.set('IsRememberMe', 1)
        const storeEmail = encryptEmail(username)
        const storePassword = encryptEmail(password)
        cookies.set('email', storeEmail)
        cookies.set('password', storePassword)
      } else {
        cookies.remove('IsRememberMe')
        cookies.remove('email')
        cookies.remove('password')
      }
    }
  }

  useEffect(() => {
    if (getRememberMe === '1') {
      setIsChecked(true)
      const getEmail = cookies.get('email')
      const getPassword = cookies.get('password')
      const originalEmail = decryptEmail(getEmail)
      const originalPassword = decryptEmail(getPassword)
      setUsername(originalEmail)
      setPassword(originalPassword)
    }
  }, [])

  const handleCredentialResponse = response => {
    signIn(response).then(response => {
      if (response.success) {
        history.replace(location?.state?.from || {pathname: `/`})
      }
    })
  }

  if (user || isLoggedIn) {
    history.replace('/')
    // navigate('/');
  }

  // useEffect(() => {
  //   /* global google */
  //   google.accounts.id.initialize({
  //     client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  //     callback: handleCredentialResponse
  //   })

  //   google.accounts.id.renderButton(
  //     document.getElementById("gSigninButton"),
  //     { theme: 'outline', size: 'large' }
  //   )
  // }, []);

  const Glogin = useGoogleLogin({
    onSuccess: codeResponse => {
      signIn(codeResponse.email, codeResponse.id).then(() => {
        history.replace(location?.state?.from || {pathname: `/`})
      })
    },
    onError: error => console.log('Login Failed:', error)
  })
  useEffect(() => {
    if (user) {
      axios
        .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
            Accept: 'application/json'
          }
        })
        .then(res => {
          setProfile(res.data)
        })
        .catch(err => console.log(err))
    }
  }, [Guser])

  const logOut = () => {
    // googleLogout();
    setProfile(null)
  }

  const handleCloseVerify = () => {
    setIsMethodChanges(!isMethodChange)
  }

  return (
    <>
      <div className="h-screen w-full lg:p-9 md:p-6 p-4">
        <div className="h-full flex justify-around border border-solid rounded-xl  border-gray-300 md:divide-x md:divide-gray-300 ">
          <div className="hidden md:block justify-center items-center  lg:w-1/2 md:w-1/2">
            <div className="h-full p-5 flex justify-center items-center">
              <img src={graphic} alt="" className="h-auto max-w-full" />
            </div>
          </div>

          <div className="flex justify-center items-center flex-col lg:w-1/2 md:w-1/2">
            <div className="flex flex-col ">
              <div className="h-full w-full flex justify-center items-center lg:pb-[20px] lg:pt-[20px] md:pb-[15px] md:pt-[15px] pb-[10px] bt-[10px]">
                <img src={logo} alt="pronto" className="h-full w-full max-w-[186px]" />
              </div>
              <div className="flex justify-center items-center font-semibold text-3xl font-manrope">Login</div>
              <div className="text-gray-400 w-full flex justify-center items-center text-lg font-manrope text-center lg:px-0 md:px-0 px-2">
                Welcome back, please enter your details to login.
              </div>
            </div>
            <div className="flex flex-col w-full lg:px-16 md:px-4 pt-6 pb-2 px-4">
              <form className="">
                <div className="flex flex-col pb-2 w-full">
                  <label className="font-semibold text-lg mb-4 font-manrope">Email</label>
                  <input
                    className="input-field-cls"
                    type="email"
                    placeholder="Enter your email address"
                    value={username}
                    onChange={e => {
                      setIsChecked(false)
                      cookies.remove('IsRememberMe')
                      cookies.remove('email')
                      cookies.remove('password')
                      setUsername(e.target.value)
                    }}
                  />
                </div>

                <div className="flex flex-col w-full">
                  <label className="font-semibold text-lg mb-4 font-manrope">Password</label>
                  <div className="relative">
                    <input
                      type={`${eyeHide === false ? 'password' : 'text'}`}
                      className="input-field-cls"
                      placeholder="xxxxxxxxxx"
                      value={password}
                      onChange={e => {
                        setIsChecked(false)
                        cookies.remove('IsRememberMe')
                        cookies.remove('email')
                        cookies.remove('password')
                        setPassword(e.target.value)
                      }}
                    />
                    {eyeHide === true ? (
                      <RemoveRedEyeIcon
                        className="absolute top-[10px] bottom-0 right-0 flex items-center justify-center pr-2"
                        onClick={() => {
                          setEyeHide(!eyeHide)
                        }}
                      />
                    ) : (
                      <VisibilityOffIcon
                        className="absolute top-[10px] bottom-0 right-0 flex items-center justify-center pr-2"
                        onClick={() => {
                          setEyeHide(!eyeHide)
                        }}
                      />
                    )}
                  </div>
                  <div className="flex  justify-between">
                    <div class="mt-4">
                      <input
                        type="checkbox"
                        id="checkbox"
                        name="rememberMe"
                        className="form-checkbox h-4 w-4 text-indigo-600 border-gray-400 rounded-lg"
                        checked={isChecked === true ? 'checked' : ''}
                        disabled={!username && !password}
                        style={{
                          cursor: !username && !password ? 'default' : 'pointer'
                        }}
                        onChange={e => {
                          rememberMeFunction(e)
                        }}
                      />
                      <label
                        for="checkbox"
                        //class="round"
                        style={{
                          cursor: !username && !password ? 'default' : 'pointer'
                        }}
                      ></label>
                      <span className="text-gray-500 ml-3 font-manrope">Remember me</span>
                    </div>

                    <div className="">
                      <button type="button" className="text-gray-500 mt-4 font-manrope" onClick={openForgotPasswordPopup}>
                        Forgot Password?
                      </button>
                    </div>
                  </div>
                  <button disabled={username === '' && password === ''} className="btn-cls" onClick={login}>
                    Login
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* select verify identity popup start  */}
      <PopupComponent
        open={isMethodChange}
        closeBtn={false}
        title={false}
        backBtn={true}
        text="Select a method to verify your identity"
        handleClick={handleCloseVerify}
      >
        <div className="flex flex-col h-full w-full">
          <div className="flex flex-col h-full">
            <div className="flex justify-between p-5 cursor-pointer">
              <div
                className="font-manrope"
                onClick={() => {
                  setIsMethodChanges(false)
                  tryOtherMethodWithPhone()
                }}
              >
                <PhoneIphoneIcon />
                Phone
              </div>
              <div className="text-2xl cursor-pointer">{`>`}</div>
            </div>
            <div className="flex justify-between p-5 cursor-pointer">
              <div
                className="font-manrope"
                onClick={() => {
                  setIsMethodChanges(false)
                  optionMethodMailVerify()
                }}
              >
                <MailIcon className="mr-4" />
                Email
              </div>
              <div className="text-2xl cursor-pointer">{`>`}</div>
            </div>
          </div>
        </div>
      </PopupComponent>
      {/* select verify identity popup end  */}

      {/* Forgot password popup start */}
      <PopupComponent open={forgotPasswordPopup} handleClose={closeForgotPasswordPopup} closeBtn={true} title={false} text="">
        {step === 0 ? (
          <>
            <h3 className="text-3xl font-bold text-gray-800 mt-6 mb-4 font-poppins">Please enter your email address</h3>
            <input
              value={emailAddress}
              onChange={e => {
                setEmailAddress(e.target.value)
              }}
              type="email"
              className="input-field-cls"
              placeholder="Example: John@gmail.com"
            />
            <button disabled={emailAddress === ''} className="btn-cls" onClick={emailAddress === '' ? () => {} : goToNextStep}>
              Next
            </button>
          </>
        ) : step === 1 ? (
          <>
            <h3 className="text-3xl font-bold text-gray-800 mt-6 mb-4 font-poppins">
              Please check your email, we have just sent you a one time pass code
            </h3>
            <input
              value={otp}
              onChange={e => {
                setOtp(e.target.value)
              }}
              type="text"
              className="input-field-cls"
              placeholder="xxxxxx"
            />
            <button disabled={otp === ''} className="btn-cls" onClick={otp === '' ? () => {} : verifyOtp}>
              Verify
            </button>
          </>
        ) : (
          ''
        )}
      </PopupComponent>
      {/* Forgot password popup end */}

      {/* Phone number input box popup for send otp start */}
      <PopupComponent
        open={fieldName === 'phone' ? true : false}
        handleClose={closeForgotPasswordPopup}
        closeBtn={true}
        title={false}
        text=""
      >
        <>
          <h3 className="text-3xl font-semibold text-gray-800 mt-6 mb-4 font-poppins">
            Please Enter your Phone number to get One Time Password(OTP)
          </h3>
          <div className="twoFaFields flex flex-col">
            <label className="font-medium pb-2 font-poppins">Phone</label>
            <PhoneInput
              inputStyle={{
                width: '100%',
                height: '50px'
              }}
              country={'ie'}
              value={phone}
              onChange={(data, country) => {
                setPhoneNo(data)
                setCountryCode(country?.dialCode)
              }}
            />
          </div>

          <button
            className="btn-cls"
            disabled={phone === ''}
            style={{
              opacity: phone === '' ? '0.4' : 1,
              cursor: phone === '' ? 'default' : 'pointer'
            }}
            onClick={() => getSMSOtp()}
          >
            Send OTP
          </button>
        </>
      </PopupComponent>
      {/* Phone number input box popup for send otp end */}

      {/* Two-Factor Authentication verify popup start  */}
      <PopupComponent
        open={fieldName === 'email' || fieldName === 'phoneVerify' ? true : false}
        handleClose={closeForgotPasswordPopup}
        closeBtn={true}
      >
        <>
          <div className="text-3xl font-bold text-gray-800 mt-6 mb-4 font-poppins">Two-Factor Authentication</div>
          <h5 className="text-lg font-medium text-gray-800 text-left mt-0 mb-10 font-poppins">
            {`${
              fieldName === 'phoneVerify'
                ? `Your account is protected with two-factor authentication. Please enter One Time Password(OTP) that has been sent to a phone number ending in ${phone.substring(
                    phone.length - 3
                  )}`
                : "Your account is protected with two-factor authentication. Please check your email for a one-time passcode we've just sent."
            }`}
          </h5>
          <label className="font-semibold text-lg mb-4 text-black font-poppins">
            <span style={{color: 'red'}}>*</span>Two-Factor Code
          </label>
          <input
            value={otp}
            onChange={e => {
              setOtp(e.target.value)
            }}
            type="text"
            className="w-full border border-solid rounded-md text-base p-4 h-12 bg-gray-50 text-black font-poppins"
            placeholder="xxxxxx"
          />
          <button
            disabled={otp === ''}
            className="btn-cls"
            style={{
              opacity: otp === '' ? '0.4' : 1,
              cursor: otp === '' ? 'default' : 'pointer'
            }}
            onClick={otp === '' ? () => {} : verifyOtp}
          >
            Verify
          </button>
          {fieldName === 'phoneVerify' && (
            <div className="flex justify-center">
              <button
                type="button"
                className="bg-transparent text-blue-500 border-none py-0 font-inherit cursor-pointer outline-inherit pt-5 underline font-poppins"
                onClick={() => {
                  setIsMethodChanges(!isMethodChange)
                  setFieldName('')
                }}
              >
                Try another method
              </button>
            </div>
          )}
        </>
      </PopupComponent>
      {/* Two-Factor Authentication verify popup end  */}
    </>
  )
}

export default Login
