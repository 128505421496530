import {useState, useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import {SecurityOutlined} from '@material-ui/icons'
import axios from 'axios'
import PasswordChecklist from 'react-password-checklist'
import Swal from 'sweetalert2'

import logo from '../images/ProntobyKaptea.svg'
import graphic from '../images/Password_Reset.svg'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import cross from '../images/tabler_x.png'
import check from '../images/check.png'
import './passwordreset.css'

export default function PasswordReset() {
  // const location = useLocation();
  const history = useHistory()

  const [token, setToken] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [updating, setUpdating] = useState(false)
  const [eyeHide, setEyeHide] = useState(false)
  const [eyeType, setEyeType] = useState(false)

  useEffect(() => {
    if (history.location.state && history.location.state.token) {
      let state = {...history.location.state}
      setToken(state.token)
      delete state.token
      history.replace({...history.location, state})
    } else {
      history.push('/')
    }
  }, [history])

  const isValidData = () => {
    let isValid = true
    let message = ''

    if (newPassword === '' || confirmPassword === '') {
      isValid = false
      message = 'All fields are mandatory'
    } else if (newPassword !== confirmPassword) {
      isValid = false
      message = 'Password and Confirm Password are not matched'
    }

    return {isValid, message}
  }

  const resetPassword = async event => {
    event.preventDefault()
    if (token) {
      const {isValid, message} = isValidData()
      if (isValid) {
        setUpdating(true)
        axios
          .post(process.env.REACT_APP_API_BASEURL + '/reset-password', {token: token, password: confirmPassword})
          .then(async function (response) {
            setUpdating(false)
            await Swal.fire({title: 'Reset password successfully', confirmButtonColor: '#3085d6'})
            history.replace('/')
          })
          .catch(async function (error) {
            setUpdating(false)
            await Swal.fire({title: 'Unable to process reset password', confirmButtonColor: '#3085d6'})
          })
      } else {
        await Swal.fire({title: message, confirmButtonColor: '#3085d6'})
      }
    } else {
      await Swal.fire({title: 'Unable to verify details, Please retry with new otp', confirmButtonColor: '#3085d6'})
      history.replace('/')
    }
  }

  return (
    <div className="h-screen w-full lg:p-9 md:p-6 p-4">
      <div className="h-full flex justify-around border border-solid rounded-xl  border-gray-300 md:divide-x md:divide-gray-300 ">
        <div className="hidden md:block justify-center items-center  lg:w-1/2 md:w-1/2">
          <div className="h-full p-5 flex justify-center items-center">
            <img src={graphic} alt="" className="h-auto max-w-full" />
          </div>
        </div>

        <div className="flex justify-center items-center flex-col lg:w-1/2 md:w-1/2">
          <div className="flex flex-col">
            <div className="h-full w-full flex justify-center items-center lg:pb-[20px] lg:pt-[20px] md:pb-[15px] md:pt-[15px] pb-[10px] bt-[5px]">
              <img src={logo} alt="pronto" className="h-full w-full max-w-[186px]" />
            </div>
            <div className="flex justify-center items-center font-semibold text-3xl font-manrope">Reset Password</div>
          </div>
          <div className="flex flex-col w-full lg:px-16 md:px-4 pt-6 pb-2 px-4">
            <form className="">
              <div className="flex flex-col pb-2 w-full">
                <label className="font-semibold text-lg mb-4 font-manrope">Password</label>
                <div className="relative">
                  <input
                    type={`${eyeHide === false ? 'password' : 'text'}`}
                    onChange={e => {
                      setNewPassword(e.target.value)
                    }}
                    className="input-field-cls"
                    placeholder="Enter a password for the user"
                  />
                  {eyeHide === true ? (
                    <RemoveRedEyeIcon
                      className="absolute top-[10px] bottom-0 right-0 flex items-center justify-center pr-2"
                      onClick={() => {
                        setEyeHide(!eyeHide)
                      }}
                    />
                  ) : (
                    <VisibilityOffIcon
                      className="absolute top-[10px] bottom-0 right-0 flex items-center justify-center pr-2"
                      onClick={() => {
                        setEyeHide(!eyeHide)
                      }}
                    />
                  )}
                </div>
              </div>

              <div className="flex flex-col w-full">
                <label className="font-semibold text-lg mb-4 font-manrope">Confirm Password</label>
                <div className="relative">
                  <input
                    type={`${eyeType === false ? 'password' : 'text'}`}
                    onChange={e => {
                      setConfirmPassword(e.target.value)
                    }}
                    className="input-field-cls"
                    placeholder="Confirm the password"
                  />
                  {eyeType === true ? (
                    <RemoveRedEyeIcon
                      className="absolute top-[10px] bottom-0 right-0 flex items-center justify-center pr-2"
                      onClick={() => {
                        setEyeType(!eyeType)
                      }}
                    />
                  ) : (
                    <VisibilityOffIcon
                      className="absolute top-[10px] bottom-0 right-0 flex items-center justify-center pr-2"
                      onClick={() => {
                        setEyeType(!eyeType)
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="flex flex-col w-full py-5">
                <PasswordChecklist
                  rules={['minLength', 'specialChar', 'number', 'capital', 'match']}
                  minLength={8}
                  value={newPassword}
                  valueAgain={confirmPassword}
                  onChange={isValid => {}}
                  iconComponents={{
                    ValidIcon: <img src={check} alt="check" className="w-[20px] h-[20px] text-green-500 mt-[6px]" />,
                    InvalidIcon: <img src={cross} alt="cross" className="w-[20px] h-[20px]" />
                  }}
                />
              </div>
              <button
                disabled={updating}
                onClick={resetPassword}
                className="w-full rounded-lg bg-[#f05865] border-none cursor-pointer py-2 px-4 text-white h-[60px] text-lg font-manrope font-medium"
              >
                Reset Now
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
