import CreateIcon from '@mui/icons-material/Create'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'

import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined'
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined'
import axios from '../../../helpers/request'
import {useState} from 'react'
import PopupComponent from '../../Popup/PopupComponent'
const WhatsAppUserInfo = propsData => {
  const [conversationName, setConversationName] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const {
    contactName,
    currentChannel,
    markUnmarkArchived,
    chatId,
    getChatList,
    isArchivePage,
    handleUnarchive,
    handleDeleteConversation
  } = propsData

  const handleUpdateName = e => {
    e.preventDefault()
    axios.post('/update-conversation-name', {conversationName, chatId}).then(res => {
      getChatList()
      setIsOpen(false)
    })
  }
  const handleClose = () => {
    setIsOpen(false)
  }
  return (
    <>
      <div className="w-full  lg:hidden block">
        <div className="">
          <div
            className="flex py-2 px-2"
            style={{borderBottom: '1px solid #c1c1c1', cursor: 'pointer'}}
            onClick={e => {
              e.stopPropagation()

              setIsOpen(!isOpen)
            }}
          >
            <CreateIcon style={{paddingRight: '10px', height: '40px', fontSize: '30px'}} />
            <div className="font-bold" style={{padding: '10px'}}>
              Add conversation name
            </div>
          </div>
          {/* <div className="d-flex p-3" style={{borderBottom: '1px solid #c1c1c1'}}>
            <i
              class="fa-regular fa-bell-slash"
              style={{paddingRight: '10px', height: '40px', display: 'flex', alignItems: 'center', fontSize: '20px'}}
            ></i>
            <div className="wp-label" style={{padding: '10px'}}>
              Mute conversation
            </div>
          </div> */}
          {!isArchivePage ? (
            <div
              className="flex py-2 px-2"
              style={{borderBottom: '1px solid #c1c1c1', cursor: 'pointer'}}
              onClick={markUnmarkArchived}
            >
              <ArchiveOutlinedIcon style={{paddingRight: '10px', height: '40px', fontSize: '30px'}} />
              <div className="font-bold" style={{padding: '10px'}}>
                Archive conversation
              </div>
            </div>
          ) : (
            <>
              <div
                className="flex py-2 px-2"
                style={{borderBottom: '1px solid #c1c1c1', cursor: 'pointer'}}
                onClick={handleUnarchive}
              >
                <UnarchiveOutlinedIcon style={{paddingRight: '10px', height: '40px', fontSize: '30px'}} />
                <div className="font-bold" style={{padding: '10px'}}>
                  Unarchive conversation
                </div>
              </div>
            </>
          )}
          <div
            className="flex py-2 px-2"
            style={{borderBottom: '1px solid #c1c1c1', cursor: 'pointer'}}
            onClick={handleDeleteConversation}
          >
            <DeleteOutlineIcon style={{paddingRight: '10px', height: '40px', fontSize: '30px'}} />
            <div className="font-bold" style={{padding: '10px'}}>
              Delete conversation
            </div>
          </div>
        </div>
        <div className="py-2 px-2">
          <div style={{fontWeight: 'bold', paddingBottom: '10px'}}>Participants:</div>
          <div className="flex border rounded-lg">
            <div className="font-bold px-2">MSG VIA:</div>
            <div className="flex justify-center w-full ">{currentChannel?.fromdata}</div>
          </div>
          <div className="participants-name">{contactName}</div>
        </div>
      </div>

      <PopupComponent open={isOpen} closeBtn={true} title={false} backBtn={false} text="" handleClose={handleClose}>
        <div className="flex flex-col visited:border-0">
          <label className="font-semibold text-lg mb-4 font-manrope">Conversation Name</label>
          <input
            type="text"
            className=" w-full border text-base h-12 bg-[#f9fafc] text-black p-4 rounded-[10px] border-solid focus:outline-none"
            onChange={e => setConversationName(e.target.value)}
          />
        </div>
        <div className="flex justify-end">
          <button
            className="bg-[#f05865] cursor-pointer text-[white] h-[60px] text-lg font-normal mt-[30px] px-2.5 py-[7px] rounded-xl"
            style={{paddingLeft: '20px'}}
            onClick={handleUpdateName}
          >
            Submit
          </button>
        </div>
      </PopupComponent>
    </>
  )
}

export default WhatsAppUserInfo
