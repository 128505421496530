import './Surveys.css'
import {DataGrid} from '@material-ui/data-grid'
import {Grid, DialogContent, Dialog} from '@mui/material'
import {styled} from '@mui/material/styles'
import {DeleteOutlined, EditOutlined, VisibilityOutlined, FlagOutlined} from '@material-ui/icons'
import {useState, useEffect} from 'react'
import axios from '../../../helpers/request'
import Cookies from 'universal-cookie'
import Swal from 'sweetalert2'
import Importcontacts from '../Contacts/Import/ImportContacts'
import {useAppState} from '../../../state'
import {useHistory, Link} from 'react-router-dom'

const BootstrapDialog = styled(Dialog)(({theme}) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    minwidth: '400px'
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2)
  }
}))

export default function Surveys() {
  const cookies = new Cookies()
  const history = useHistory()
  const {user: loggedUser} = useAppState()
  const userToken = cookies.get('cs_token')
  const [data, setData] = useState([])
  const [updateModalSize, setUpdateModalSize] = useState(false)
  const [opencontactModal, setOpencontactModal] = useState(false)

  const getSurveys = () => {
    axios
      .post(`${process.env.REACT_APP_API_BASEURL}/userdetails`, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      })
      .then(res => {
        if (res.data.length > 0) {
          axios.get(`/surveys/${res.data[0].added_by ? res.data[0].added_by : res.data[0].id}`).then(async response => {
            if (response.status === 200 && response.data && response.data.data) {
              setData(response.data.data.map(v => ({...v, id: v.flowId})))
            } else {
              throw new Error(response?.error)
            }
          })
        }
      })
      .catch(err => {
        console.log('errr', err)
      })
  }

  const sendSurveys = dataArray => {
    axios
      .post(
        `${process.env.REACT_APP_API_BASEURL}/surveys/execute/${opencontactModal}`,
        {
          contactList: dataArray,
          sentBy: loggedUser.fullName,
          companyId: loggedUser.added_by ? loggedUser.added_by : loggedUser.id
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`
          }
        }
      )
      .then(response => {
        if (response.status === 200 && response.data.success) {
          Swal.fire({
            title: `Survey sent successfully`,
            confirmButtonColor: '#3085d6'
          }).then(() => {
            history.push('/analytic-survey')
          })
        }
      })
      .catch(err => {
        Swal.fire({title: 'Unable to process this request', confirmButtonColor: '#3085d6'})
      })
  }

  useEffect(() => {
    getSurveys()
  }, [])

  const handleDelete = async (id, label) => {
    const result = await Swal.fire({
      title: `Are you sure you want to delete ${label}`,
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    })

    // setData(data.filter((item) => item.id !== id));
    // const confirmDelete = window.confirm(`Are you sure to delete ${label}`);
    if (result.isConfirmed) {
      axios
        .delete(process.env.REACT_APP_API_BASEURL + '/surveys/' + id)
        .then(async function (response) {
          getSurveys()
          await Swal.fire({title: 'Survey deleted successfully', confirmButtonColor: '#3085d6'})
        })
        .catch(async function (error) {
          await Swal.fire({title: error?.response?.data?.message || 'Unable to delete survey', confirmButtonColor: '#3085d6'})
        })
    }
  }

  const columns = [
    {field: 'flowId', flex: 1, headerName: 'ID', width: 90},
    {field: 'name', flex: 1, headerName: 'Label', width: 200},
    {field: 'questions', flex: 1, headerName: 'Questions', width: 200},
    {field: 'created_at', flex: 1, headerName: 'Created At', width: 200},
    {field: 'updated_at', flex: 1, headerName: 'Updated At', width: 200},
    {
      field: 'action',
      flex: 1,
      headerName: 'Action',
      width: 400,
      renderCell: params => {
        return (
          <div className="userListActions">
            <Link to={'/survey-view/' + params.row.id}>
              <button className="actionElementButton">
                <VisibilityOutlined className="actionElementIcon" />
                <span className="actionButtonText">View</span>
              </button>
            </Link>
            <Link to={'/survey-edit/' + params.row.id}>
              <button className="actionElementButton">
                <EditOutlined className="actionElementIcon" />
                <span className="actionButtonText">Edit</span>
              </button>
            </Link>
            <button
              className="actionElementButton"
              onClick={() => {
                setOpencontactModal(params.row.id)
              }}
            >
              <FlagOutlined className="actionElementIcon" />
              <span className="actionButtonText">Send</span>
            </button>
            <DeleteOutlined className="userListDelete" onClick={() => handleDelete(params.row.id, params.row.label)} />
          </div>
        )
      }
    }
  ]

  return (
    <div>
      <div className="pageSubHeader flex-column">
        <div className="align-self-end">
          <Link to="/newSurvey">
            <button className="min-w-[137px] bg-[#f05865] cursor-pointer text-[white] h-[60px] text-lg font-normal mt-[30px] px-2.5 py-[7px] rounded-xl">
              Add
            </button>
          </Link>
        </div>
      </div>
      <DataGrid
        rows={data}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[5]}
        checkboxSelection
        disableSelectionOnClick
        autoHeight={true}
      />
      <BootstrapDialog
        onClose={() => setOpencontactModal(false)}
        aria-labelledby="customized-dialog-title-1"
        open={opencontactModal}
        className={updateModalSize ? 'importContactInModal extendModalSize' : 'importContactInModal '}
      >
        <DialogContent dividers>
          <Grid container>
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              spacing={2}
              className="dialogTitle-component import-contact-header-grid popup-inner"
            >
              Upload Contacts
              <button id="mypopup" className="popupElementButton close-btn" onClick={() => setOpencontactModal(false)}>
                <span className="templateButtonText">X</span>
              </button>
            </Grid>
            <Grid item xs={12} md={12} lg={12} spacing={2}>
              <Importcontacts
                componentCalledSource={'smsChannel'}
                getUploadedContactData={sendSurveys}
                setUpdateModalSize={setUpdateModalSize}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </BootstrapDialog>
    </div>
  )
}
