import moment from 'moment'
import userProfile from '../../../components/images/60111.jpg'
import sendIcon from '../../../components/images/iconamoon_send-fill.png'
import mediaIcon from '../../../components/images/add_media.png'
import {useContext, useEffect, useRef, useState} from 'react'
import {SocketContext} from '../../../socket'
import {useAppState} from '../../../state'
import {useHistory, useLocation} from 'react-router-dom'
import axios from '../../../helpers/request'
import WhatsAppPopup from '../../whatsapp-popup/WhatsAppPopup'
import {getTimeDetails} from '../../../helpers/utils'
import CameraAltIcon from '@material-ui/icons/CameraAlt'

import PlayCircleIcon from '@mui/icons-material/PlayCircle'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import NOTFOUNDIMG from '../../../components/images/Email_img.svg'
import Swal from 'sweetalert2'
import PopupComponent from '../../Popup/PopupComponent'
import CustomMenu from '../../Popup/CustomMenu'
import WhatsAppUserInfo from '../Whatsapp-Channel/WhatsAppUserInfo'
const WhatsAppComponent = () => {
  const socket = useContext(SocketContext)
  const {user} = useAppState()
  const location = useLocation()
  const [chats, setChats] = useState([])
  const [conversation, setConversation] = useState([])
  const [channelList, setChannelList] = useState([])
  const [currentChannel, setCurrentChannel] = useState(null)
  const [message, setMessage] = useState('')
  const [convId, setConversationId] = useState('')
  const [chatId, setChatId] = useState('')
  const [loading, setLoading] = useState(false)
  const [buttonPopup, setButtonPopup] = useState(false)
  const [openChat, setOpenChat] = useState(false)
  const [contactNo, setContactNo] = useState('')
  const [conversationName, setConversationName] = useState('')
  const [templateBody, setTemplateBody] = useState('')
  const [mobileNoUpdate, setMobileNoUpdate] = useState('')
  const [allData, setAllData] = useState('')
  const [isUserProfile, setUserProfile] = useState(false)
  const [contactName, setContactName] = useState('')
  const [openTemplate, setOpenTemplate] = useState(true)
  const [currentTemplateSent, setTemplateSent] = useState('')
  const [templateData, setTemplateData] = useState([])
  const [isOpen, setIsopen] = useState(false)
  const [openMenu, setOpenMenu] = useState(false)
  const [imagePreview, setImagePreview] = useState(false)
  const [mediaUrl, setMediaUrl] = useState('')
  const [mediaType, setMediaType] = useState('')
  const [fileStore, setFileStore] = useState('')
  const [file, setFile] = useState()
  const [isSizeBig, setIsSizeBig] = useState(false)
  const [popupModel, setPopupModel] = useState('')
  const [archiveNumber, setArchiveNumber] = useState('')
  const [archiveCampaignid, setArchiveCampaignid] = useState('')
  const [archiveChat, setArchiveChat] = useState([])
  const [phone, setPhoneNo] = useState('')
  const [isNewOpen, setIsNewOpen] = useState(false)
  const [chatsNumber, SetChatsNumber] = useState('')

  const [selectedContact, setSelectedContact] = useState('')
  const [notificationMessage, setNotificationMessage] = useState('')
  const [searchField, setSearchField] = useState('')
  const isArchivePage = location.pathname.includes('archive')
  const history = useHistory()
  const chatRef = useRef()
  /**
   * get chat list api call function
   */
  const getChatList = () => {
    setButtonPopup(false)
    axios
      .get(
        `/${!isArchivePage ? 'get-chats' : 'get-archives'}/${user.added_by ? user.added_by : user.id}${searchField && `?search=${searchField}`}`
      )
      .then(async response => {
        if (response.status === 200 && response.data && response.data.length) {
          let list = response.data.filter(v => v.campaignid.includes('-sends-'))

          setChats(list)
          setConversationId(list[0].campaignid)
          setChatId(list[0].id)

          setTemplateSent({chatID: list[0].id, templateSent: list[0]?.templateSent, campaignid: list[0].campaignid})
        } else {
          console.log('error', response?.error)
          throw new Error(response?.error)
        }
      })
      .catch(err => {
        console.log('errr', err)
      })
  }

  /**
   * get api for whatsapp channel
   */
  const getWhatsappChannels = () => {
    axios
      .get(`/wp-channels/${user.added_by ? user.added_by : user.id}`)
      .then(async response => {
        if (response.status === 200 && response.data) {
          setChannelList(response.data)
        } else {
          throw new Error(response?.error)
        }
      })
      .catch(err => {
        console.log('errr', err)
      })
  }

  const getArchivesList = () => {
    axios
      .get(`/get-archives/${user.added_by ? user.added_by : user.id}`)
      .then(async response => {
        if (response.status === 200 && response.data && response.data.length) {
          let list = response.data.filter(v => v.campaignid.includes('-sends-'))
          setArchiveChat(list)

          setArchiveCampaignid(list[0].campaignid)
        } else {
          console.log('error', response?.error)
          //throw new Error(response?.error)
        }
      })
      .catch(err => {
        console.log('errr', err)
      })
  }

  /**
   * get templates api call function
   * @param {*} id
   */
  const getTemplates = id => {
    axios
      .get(`/wp-templates/${id}`)
      .then(async response => {
        if (response.status === 200 && response.data) {
          setTemplateData(response.data)
        } else {
          throw new Error(response?.error)
        }
      })
      .catch(err => {
        console.log('errr', err)
      })
  }

  /**
   * Api call for get conversation
   * @param {*} conversationId
   */
  const getConversation = conversationId => {
    console.log(channelList, 'conversationId')
    axios
      .get(`/get-chat-messages/${conversationId}`)
      .then(async response => {
        if (response.status === 200 && response.data && response.data.length) {
          setConversation(response.data)
          setCurrentChannel(channelList.filter(v => v.fromdata == conversationId.split('-sends-')[0])[0])
          if (currentTemplateSent?.templateSent === 'senderTemplate') {
            getTemplates(channelList.filter(v => v.fromdata == conversationId.split('-sends-')[0])[0]?.id)
          } else if (currentTemplateSent?.templateSent === 'receiveTemplate') {
            setOpenTemplate(false)
          }
        } else {
          throw new Error(response?.error)
        }
      })
      .catch(err => {
        console.log('errr', err)
      })
  }

  useEffect(() => {
    socket.on('notification_count', data => {
      setNotificationMessage({phoneNumber: data?.campaignId?.split('-sends-')[1], message: data?.Body})
    })
  }, [socket])

  /**
   * use effect for get incoming message
   */
  useEffect(() => {
    socket.on('new_message', () => {
      if (currentTemplateSent?.campaignid === convId) {
        getChatList()
        setOpenTemplate(false)
      }

      if (isArchivePage) {
        history.push('/whatsapp')
      }
      getConversation(convId)
    })
    socket.on('wp_status', () => {
      getConversation(convId)
    })
    if (convId) {
      socket.emit('notification_register', {
        customer: convId.split('-sends-')[1],
        channel: convId.split('-sends-')[0],
        user: user.id
      })
      getConversation(convId)
    }
    return () => {
      socket.off('new_message')
      socket.off('wp_status')
    }
  }, [convId])

  useEffect(() => {
    if (chatRef) {
      chatRef.current?.lastElementChild?.scrollIntoView()
    }
  }, [conversation])

  useEffect(() => {
    /* getChatList() */
    getArchivesList()
    getWhatsappChannels()
  }, [])

  useEffect(() => {
    getChatList()
  }, [searchField])

  useEffect(() => {
    SetChatsNumber(chats?.map(data => data.campaignid.split('-sends-')[1]))
    setArchiveNumber(archiveChat?.map(archiveData => archiveData.campaignid.split('-sends-')[1]))
  }, [chats, archiveChat])
  /**
   * updating template sent for managing select template functionality
   * @param {*} conversationId
   * @returns
   */
  const checkMessageAPi = async conversationId => {
    try {
      const response = axios.get(`/check-message-status/${conversationId}`).then(res => {
        if (res?.data?.data === 'senderTemplate') {
          setTemplateSent({})

          getChatList()
          getConversation(conversationId)
          getTemplates(channelList.filter(v => v.fromdata == conversationId.split('-sends-')[0])[0]?.id)
          setOpenTemplate(true)
        } else if (res?.data?.data === 'receiveTemplate') {
          getChatList()
          getTemplates(currentChannel?.id)
          setOpenTemplate(false)
        }
      })
      return response
    } catch (error) {
      console.log('error', error)
    }
  }

  /**
   * use effect with set interval to check agent last message
   * duration if 24 hour then agent can send template again
   */
  useEffect(() => {
    if (convId) {
      const intervalId = setInterval(async () => {
        checkMessageAPi(convId)
      }, 300000)
      return () => {
        clearInterval(intervalId)
      }
    }
  }, [convId])

  const handleKeyPress = event => {
    if (event.key === 'Enter' && message && convId) {
      sendMessage()
    }
  }

  /**
   * create new chat and send new message function
   * @param {*} event
   */
  const newMessage = event => {
    event.preventDefault()

    const {phone, messageBody, templateSid, fromdata, sid, token, companyid, fullName, serviceId, dropdown} = allData
    axios
      .post('/send-wp-message-channel', {
        data: {
          contentSid: templateSid,
          fromdata,
          sid,
          token,
          companyid,
          messageBody,
          serviceId,
          contactNumber: dropdown === true ? phone : `+${phone}`
        },
        actionBy: fullName
      })
      .then(function (response) {
        setAllData('')

        getChatList()
        setOpenChat(false)
      })
      .catch(function (error) {
        Swal.fire({title: 'Unable to process this request', confirmButtonColor: '#3085d6'})
      })
  }

  /**
   * send message api function
   */
  const sendMessage = () => {
    setLoading(true)
    axios
      .post(`/send-outgoing-wp-message`, {
        contactNumber: convId.split('-sends-')[1],
        messageBody: message,
        actionBy: user.fullName,
        templateSent: currentTemplateSent?.templateSent,
        ...currentChannel
      })
      .then(async response => {
        if (currentTemplateSent?.templateSent === 'senderTemplate') {
          getChatList()
        }
        if (response.status === 200 && response.data) {
          getChatList()
          getConversation(convId)
          setMessage('')
        } else {
          throw new Error(response?.error)
        }
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
        console.log('errr', err)
      })
  }

  const handleCloseTemplate = () => {
    setIsopen(false)
  }

  const handleSelectTemplate = temp => {
    if (temp) {
      let messageBody = temp.types['twilio/text'].body

      if (temp?.variables) {
        for (const key in temp?.variables) {
          if (temp?.variables.hasOwnProperty(key)) {
            messageBody = messageBody.replace(`{{${key}}}`, temp?.variables[key])
          }
        }
      }
      setMessage(messageBody)
      setIsopen(false)
      setOpenTemplate(false)
    }
  }

  const handleImagePreview = (message, messageType) => {
    setPopupModel('SelectFilePreview')
    setMediaUrl(message?.mediaUrl)
    setMediaType(messageType)
  }

  const handleCloseDialog = () => {
    setImagePreview(false)
  }

  /**
   * get file type
   * @param {*} fileType
   * @returns
   */
  const getFileType = fileType => {
    try {
      const imageType = ['image/jpeg', 'image/png', 'image/jpg']
      const video = ['video/mp4']
      const documentType = ['application/pdf', 'docx', 'txt', 'doc']
      if (imageType.includes(fileType)) {
        return 'IMG'
      } else if (video.includes(fileType)) {
        return 'VIDEO'
      } else if (documentType.includes(fileType)) {
        return 'DOC'
      } else {
        return 'NONE'
      }
    } catch (error) {
      console.log('get file type service error:', error)
      throw error
    }
  }

  /**
   * function for onChange file
   * @param {*} e
   */
  const handleChange = e => {
    e.preventDefault()

    if (e.target.files[0].size) {
      var totalSizeMB = e.target.files[0].size / Math.pow(1024, 2)
      const imgSize = totalSizeMB.toFixed()
      if (imgSize > 5) {
        setIsSizeBig(true)
        Swal.fire({
          title: 'Please upload image less then 5 MB',
          confirmButtonColor: '#3085d6'
        })
      } else {
        setIsSizeBig(false)
      }
    }
    const type = getFileType(e.target.files[0]?.type)
    setFileStore(e.target.files[0])
    setFile(URL.createObjectURL(e.target.files[0]))
    setMediaType(type)
    setPopupModel('SelectUploadFile')
    setOpenMenu(false)
    //whatsappMedia
  }

  /**
   * archived chat function
   */
  const markUnmarkArchived = () => {
    setLoading(true)
    axios
      .put(`${!isArchivePage ? '/mark-archived' : '/mark-inboxed'}`, {
        campaignid: convId
      })
      .then(async response => {
        if (response.status === 200 && response.data) {
          getChatList()
          setConversation([])
          setConversationId('')
          setMessage('')
          history.push('/whatsapp-archives')
        } else {
          throw new Error(response?.error)
        }
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
        console.log('errr', err)
      })
  }

  const handleUnarchive = async () => {
    const ArchiveCampaignId = archiveChat?.filter(
      data =>
        phone == data?.campaignid?.split('-sends-')?.[1] ||
        selectedContact == data?.campaignid?.split('-sends-')?.[1] ||
        contactNo == data?.campaignid?.split('-sends-')?.[1]
    )[0]
    const campaignid = ArchiveCampaignId ? ArchiveCampaignId : {campaignid: archiveCampaignid}

    await axios
      .put('/update-archive-status', campaignid)
      .then(res => {
        //setArchivePopup(false)
        getChatList()
        setPopupModel('')
        history.push('/whatsapp')
      })
      .catch(err => {
        console.log('Unarchive error==', err)
      })
  }

  const handleDeleteConversation = async () => {
    try {
      const ArchiveCampaignId = archiveChat?.filter(data => contactNo == data?.campaignid?.split('-sends-')[1])[0]

      const campaignid =
        isArchivePage && ArchiveCampaignId?.campaignid
          ? ArchiveCampaignId?.campaignid
          : archiveCampaignid && isArchivePage
            ? archiveCampaignid
            : convId
      await axios
        .delete(`/delete-conversation/${campaignid}`)
        .then(res => {
          if (res?.statusText === 'OK') {
            getChatList()
            history.push('/whatsapp')
          }
        })
        .catch(error => {
          console.log('delete conversation api error::', error)
        })
    } catch (error) {
      console.log('DELETE CONVERSATION ERROR:', error)
    }
  }

  /**
   * This function is for mobile view user info page
   * @param {*} number
   */
  const handleClickUserInfo = number => {
    conversation.forEach(data => {
      if (number === data?.customernumber) {
        setContactName(data?.fullName)
      } else {
        setContactName('')
      }
    })
    setUserProfile(true)
  }

  const handleUpdateMessageStatus = async campaignId => {
    await axios.put('/update-message-status', {campaignId}).then(res => {
      getConversation(campaignId)
    })
  }

  return (
    <>
      <div className="w-full flex border rounded-xl min-h-full lg:mt-0 md:mt-0 mt-[10px]">
        <div className={`w-full lg:w-[30%] flex-col h-full  ${isNewOpen ? 'hidden lg:block' : 'block'}`}>
          <div className="rounded-tl-xl rounded-tr-xl md:rounded-tr-none flex justify-center items-center bg-[#ef5864] font-manrope text-white p-2">
            <div className="text-xl">Message</div>
          </div>
          <div className="flex justify-center items-center my-3 px-3 w-full relative">
            <input
              type="text"
              placeholder="Search"
              value={searchField}
              className="h-[40px] w-full border rounded-full placeholder-[#62585D] text-left pl-10 focus:outline-none"
              onChange={e => setSearchField(e.target.value)}
            />
            <i class="fa-solid fa-magnifying-glass absolute left-[30px] text-[#62585D]"></i>
          </div>
          {!isArchivePage && (
            <div className="px-3  flex items-center pb-2">
              <button
                className="w-full flex h-[40px] justify-center items-center rounded-full  bg-[#ef5864] border-none cursor-pointer py-2 px-4 pl-8 pr-4 text-white text-base font-manrope font-normal"
                onClick={() => {
                  setPopupModel('selectContact')
                }}
              >
                <i className="fa-light fa-plus text-3xl text-center not-italic text-white pr-2"></i>Start New Chat
              </button>
            </div>
          )}
          <div className="min-h-[calc(100vh-258px)] h-full overflow-auto">
            {allData && !isUserProfile && (
              <div className="px-2 py-5 flex cursor-pointer border-l-2 border-[#F05865] bg-[#FFF4F7]">
                <div className="w-[60px] h-[50px] rounded-full">
                  <img src={userProfile} alt="profile-img" className="object-cover object-center rounded-full w-full h-full" />
                </div>
                <div className="flex flex-col justify-center items-start pl-2 w-[80%]">
                  <div className="text-base font-semibold">{allData?.phone}</div>
                </div>
                <div className="flex flex-col justify-between items-center w-[20%]">
                  <div className="text-[11px]">{getTimeDetails(new Date())}</div>
                  <div className="rounded-full bg-[#569FE5] w-[20px] h-[20px] text-sm text-center">1</div>
                </div>
              </div>
            )}
            {chats?.length > 0 &&
              chats.map((chatListData, index) => {
                let chatData = chatListData.campaignid.split('-sends-')
                return (
                  <div
                    className={`px-2 py-5 flex cursor-pointer ${chatListData.id === chatId && !allData ? 'border-l-4 border-[#F05865] bg-[#FFF4F7]' : ''}`}
                    onClick={() => {
                      setContactNo(chatData?.[1])
                      setConversationName(chatListData?.conversationName)
                      setChatId(chatListData.id)
                      setTemplateSent({
                        chatID: chatListData.id,
                        templateSent: chatListData?.templateSent,
                        campaignid: chatListData?.campaignid
                      })
                      setConversationId(chatListData.campaignid)
                      setIsNewOpen(!isNewOpen)
                      handleUpdateMessageStatus(chatListData.campaignid)
                    }}
                  >
                    <div className="w-[60px] h-[50px] rounded-full">
                      <img
                        src={userProfile}
                        alt="profile-img"
                        className="object-cover object-center rounded-full w-full h-full"
                      />
                    </div>
                    <div className="flex flex-col justify-center items-start pl-2 w-[80%]">
                      <div className="text-base font-semibold">
                        {chatListData?.conversationName ? chatListData?.conversationName : chatData[1]}
                      </div>
                      <div className="text-[#888587] text-sm">
                        {chatListData?.message && chatListData?.message.length > 0 ? (
                          chatListData?.message.substring(0, 20) + '....'
                        ) : chatListData?.mediaUrl &&
                          (chatListData?.messageType === 'IMG' || chatListData?.messageType === 'image') ? (
                          <div className="flex">
                            <CameraAltIcon fontSize="small" color="gray" htmlColor="gray" />{' '}
                            <div style={{paddingLeft: '10px', fontSize: '11px', display: 'flex', alignItems: 'center'}}>
                              photo
                            </div>
                          </div>
                        ) : chatListData?.mediaUrl &&
                          (chatListData?.messageType === 'VIDEO' || chatListData?.messageType === 'video') ? (
                          <div className="flex">
                            <PlayCircleIcon fontSize="small" color="gray" htmlColor="gray" />{' '}
                            <div
                              style={{
                                paddingLeft: '10px',
                                fontSize: '11px',
                                display: 'flex !important',
                                alignItems: 'center'
                              }}
                            >
                              video
                            </div>
                          </div>
                        ) : (
                          chatListData?.message
                        )}
                      </div>
                    </div>
                    {console.log(chatListData, 'chatListData')}
                    <div className="flex flex-col justify-between items-center w-[20%]">
                      <div className="text-[11px]">{getTimeDetails(new Date(chatListData.dateTimeOfMessage))}</div>
                      {notificationMessage?.phoneNumber == chatData[1] ? (
                        <div className="rounded-full bg-[#569FE5] w-[20px] h-[20px] text-sm text-center text-white">1</div>
                      ) : chatListData?.count ? (
                        <div className="rounded-full bg-[#569FE5] w-[20px] h-[20px] text-sm text-center text-white">
                          {chatListData?.count}
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
        <div className={`w-full lg:w-[70%] h-full border-l relative   ${isNewOpen ? 'block ' : ' hidden lg:block '}`}>
          <div className="px-2 lg:hidden border bg-[#f05865] h-12 w-full flex items-center">
            <div
              className="text-2xl text-white mt-[-2px] mr-1 cursor-pointer"
              onClick={() => {
                setIsNewOpen(!isNewOpen)
                setUserProfile(false)
              }}
            >{`<`}</div>

            <div
              className="text-white cursor-pointer"
              onClick={() => {
                handleClickUserInfo(contactNo)
              }}
            >
              {conversationName ? conversationName : contactNo ? contactNo : allData?.phone}
            </div>
          </div>
          <div
            class={`w-full mx-auto ${chats?.length > 0 || conversation?.length > 0 || allData ? 'lg:max-h-[calc(100vh-155px)] lg:min-h-[calc(100vh-155px)]' : 'lg:max-h-[calc(100vh-110px)] lg:min-h-[calc(100vh-110px)] md:max-h-[calc(100vh-110px)] md:min-h-[calc(100vh-110px)]'}  h-full rounded-lg`}
          >
            {!allData && !isUserProfile && (
              <div class="flex flex-row justify-between bg-white">
                <div class="w-full px-5 flex flex-col justify-between">
                  <div class="flex flex-col mt-5 overflow-auto lg:max-h-[calc(100vh-180px)] md:max-h-[calc(100vh-220px)] max-h-[calc(100vh-220px)] min-h-[calc(100vh-235px)]">
                    <div id="chat" ref={chatRef}>
                      {chats.length > 0 || allData ? (
                        conversation.map((conversationMessages, index) => (
                          <>
                            {conversationMessages.type == 1 ? (
                              <div className="flex flex-col justify-end  items-start pb-5">
                                <div class="mr-2 py-4 px-4 bg-[#EAF3FC] max-w-[calc(100%-40px)] md:max-w-[calc(100%-160px)]  rounded-tr-2xl rounded-br-2xl rounded-tl-xl text-black ">
                                  {(conversationMessages?.mediaUrl && conversationMessages?.messageType === 'IMG') ||
                                  conversationMessages?.messageType === 'image' ? (
                                    <img
                                      src={conversationMessages.mediaUrl}
                                      alt="media-url"
                                      style={{maxWidth: '170px'}}
                                      onClick={() => {
                                        handleImagePreview(conversationMessages, 'IMG')
                                      }}
                                    />
                                  ) : conversationMessages?.mediaUrl &&
                                    (conversationMessages?.messageType === 'VIDEO' ||
                                      conversationMessages?.messageType === 'video') ? (
                                    <div>
                                      <video
                                        style={{maxWidth: '200px'}}
                                        controls
                                        onClick={() => {
                                          handleImagePreview(conversationMessages, 'VIDEO')
                                        }}
                                      >
                                        <source
                                          src={conversationMessages?.mediaUrl}
                                          type='video/mp4; codecs="avc1.4d002a, mp4a.40.2"'
                                        />
                                      </video>
                                    </div>
                                  ) : (
                                    <p class="text-small mb-0 text-black">{conversationMessages.messagebody}</p>
                                  )}
                                </div>
                                <div className="flex justify-end text-[#62585D] mr-[6px] text-[11px] mt-1">{`${moment(conversationMessages.messagetime).format('hh:mm A')} | ${conversationMessages.status === 'read' ? 'Read' : conversationMessages.status === 'delivered' ? 'Delivered' : 'Sent'} `}</div>
                              </div>
                            ) : (
                              <>
                                <div className="flex  flex-col justify-start items-end pb-5">
                                  <div
                                    className={`ml-2 py-3 px-4 ${conversationMessages?.mediaUrl ? 'bg-transparent border' : 'bg-[#27AE60]'}  max-w-[calc(100%-40px)] md:max-w-[calc(100%-160px)] rounded-bl-2xl rounded-tr-2xl rounded-tl-2xl text-white`}
                                  >
                                    {conversationMessages?.mediaUrl && conversationMessages?.messageType === 'IMG' ? (
                                      <img
                                        src={conversationMessages.mediaUrl}
                                        alt="media-url"
                                        style={{maxWidth: '170px'}}
                                        onClick={() => {
                                          handleImagePreview(conversationMessages, 'IMG')
                                        }}
                                      />
                                    ) : conversationMessages?.mediaUrl && conversationMessages?.messageType === 'VIDEO' ? (
                                      <div>
                                        <video
                                          style={{maxWidth: '200px'}}
                                          controls
                                          onClick={() => {
                                            handleImagePreview(conversationMessages, 'VIDEO')
                                          }}
                                        >
                                          <source
                                            src={conversationMessages?.mediaUrl}
                                            type='video/mp4; codecs="avc1.4d002a, mp4a.40.2"'
                                          />
                                        </video>
                                      </div>
                                    ) : (
                                      <p class="text-small mb-0 text-white">{conversationMessages.messagebody}</p>
                                    )}
                                  </div>
                                  <div className="flex justify-start text-[#62585D] ml-[6px] text-[11px] mt-1">{`${moment(conversationMessages.messagetime).format('hh:mm A')} | ${conversationMessages.status === 'read' ? 'Read' : conversationMessages.status === 'delivered' ? 'Delivered' : 'Sent'} `}</div>
                                </div>
                              </>
                            )}
                          </>
                        ))
                      ) : (
                        <>
                          <div className="flex flex-col items-center justify-center h-auto min-h-[calc(100vh-200px)]">
                            <div className="w-[208px] h-[150px]">
                              <img src={NOTFOUNDIMG} alt="no-message-found" />
                            </div>
                            <div className="text-2xl font-bold">Sorry, No message were found</div>
                            <div className="text-sm">Please send a new message to start conversation</div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {console.log(currentTemplateSent?.chatID, chatId, !isUserProfile, currentTemplateSent?.templateSent, 'CONDITIOON')}
          {!allData && !isUserProfile && (
            <div>
              {currentTemplateSent?.chatID === chatId &&
              !isUserProfile &&
              (currentTemplateSent?.templateSent === 'senderTemplate' ||
                currentTemplateSent?.templateSent === 'receiveTemplate') ? (
                <div class="pb-[10px] relative px-2 flex w-full items-center">
                  <img
                    src={mediaIcon}
                    alt="media-icon"
                    class=" w-[40px] h-[35px] text-center pr-2 cursor-pointer"
                    onClick={() => setOpenMenu(!openMenu)}
                  />
                  <div class="relative w-full flex justify-between items-center bg-[#F0F2F5] rounded-full">
                    {openMenu && (
                      <div className="">
                        <CustomMenu
                          setOpenMenu={setOpenMenu}
                          openMenu={openMenu}
                          currentChannel={currentChannel}
                          contactNumber={convId.split('-sends-')[1]}
                          user={user}
                          handleChange={handleChange}
                          markUnmarkArchived={markUnmarkArchived}
                          isArchivePage={isArchivePage}
                          handleUnarchive={handleUnarchive}
                          handleDeleteConversation={handleDeleteConversation}
                        />
                      </div>
                    )}
                    <input
                      class="w-full  py-2 bg-[#F0F2F5] px-3 pl-5  rounded-full  focus:outline-none"
                      type="text"
                      disabled={!convId || loading || isArchivePage}
                      placeholder="type your message here..."
                      value={message}
                      onKeyDown={handleKeyPress}
                      onClick={() => {
                        if (openTemplate) {
                          setIsopen(true)
                        }
                      }}
                      onChange={e => {
                        setMessage(e.target.value)
                      }}
                    />
                    <img
                      src={sendIcon}
                      class=" w-[30px] h-[24px] text-center pr-2 cursor-pointer"
                      alt="send-icon"
                      onClick={e => {
                        e.preventDefault()
                        if (convId && message) {
                          sendMessage()
                        }
                      }}
                    />
                  </div>
                </div>
              ) : currentTemplateSent && currentTemplateSent?.chatID === chatId && !isUserProfile ? (
                <div className="flex justify-center item-center h-[50px]">
                  <div className="">
                    <WhatsAppIcon style={{color: 'green'}} /> Waiting for reply.
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          )}

          {allData && !isUserProfile && (
            <div class="pb-[10px] relative px-2 flex w-full items-center">
              <img src={mediaIcon} alt="media-icon" class=" w-[40px] h-[35px] text-center pr-2" />
              <div class="relative w-full flex justify-between items-center bg-[#F0F2F5] rounded-full">
                <input
                  class="w-full  py-2 bg-[#F0F2F5] px-3 pl-5  rounded-full  focus:outline-none"
                  type="text"
                  disabled={!convId || loading || templateBody || isArchivePage}
                  placeholder="type your message here..."
                  value={allData?.messageBody}
                  onKeyDown={handleKeyPress}
                  onChange={e => {
                    setMessage(e.target.value)
                  }}
                />
                <img
                  src={sendIcon}
                  class=" w-[30px] h-[24px] text-center pr-2 cursor-pointer"
                  alt="send-icon"
                  onClick={e => {
                    if (allData) {
                      newMessage(e)
                    }
                  }}
                />
              </div>
            </div>
          )}

          {/* Mobile view user info page start  */}
          {isUserProfile === true && (
            <WhatsAppUserInfo
              contactName={contactName}
              currentChannel={currentChannel}
              markUnmarkArchived={markUnmarkArchived}
              chatId={chatId}
              getChatList={getChatList}
              isArchivePage={isArchivePage}
              handleUnarchive={handleUnarchive}
              handleDeleteConversation={handleDeleteConversation}
            />
          )}
          {/* Mobile view user info page start  */}
        </div>
      </div>
      <PopupComponent
        open={isOpen}
        closeBtn={true}
        title={false}
        backBtn={false}
        text="Select a WhatsApp template:"
        handleClose={handleCloseTemplate}
      >
        <div className="w-full max-w-[550px] min-w-[550px]">
          {templateData.length > 0 &&
            templateData.map((temp, index) => (
              <div key={index}>
                <div
                  className="pb-3"
                  onClick={() => {
                    handleSelectTemplate(temp)
                  }}
                >
                  <div className="border rounded-lg flex h-[40px]  items-center pl-2 bg-[#F9FAFC] cursor-pointer">
                    <div>
                      <WhatsAppIcon style={{color: 'green'}} />
                    </div>
                    <div className="wp-main-label-temp">{temp?.friendly_name}</div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </PopupComponent>
      <WhatsAppPopup
        setPopupModel={setPopupModel}
        popupModel={popupModel}
        user={user}
        setAllData={setAllData}
        setMessage={setMessage}
        phone={phone}
        setPhoneNo={setPhoneNo}
        setSelectedContact={setSelectedContact}
        selectedContact={selectedContact}
        currentChannel={currentChannel}
        convId={convId}
        fileStore={fileStore}
        currentTemplateSent={currentTemplateSent}
        mediaType={mediaType}
        file={file}
        isSizeBig={isSizeBig}
        loading={loading}
        mediaUrl={mediaUrl}
        getChatList={getChatList}
        handleUnarchive={handleUnarchive}
        SetChatsNumber={SetChatsNumber}
        chatsNumber={chatsNumber}
      />
    </>
  )
}

export default WhatsAppComponent
