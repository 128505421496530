import axios from '../../helpers/request'
import './admins.css'
import {DataGrid} from '@material-ui/data-grid'
import {DeleteOutlined, EditOutlined, VisibilityOutlined, AddOutlined} from '@material-ui/icons'
import {Link} from 'react-router-dom'
import {useState, useEffect} from 'react'
import Cookies from 'universal-cookie'
import Swal from 'sweetalert2'

export default function Admins({userDetails}) {
  const [data, setData] = useState([])
  const cookies = new Cookies()

  const user_data_cookies = cookies.get('cs_user')

  const handleDelete = async (id, name) => {
    const result = await Swal.fire({
      title: `Are you sure you want to delete ${name}`,
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    })

    if (result.isConfirmed) {
      axios
        .delete(process.env.REACT_APP_API_BASEURL + '/user/' + id)
        .then(async function (response) {
          getAdminUsers()
          await Swal.fire({
            title: 'User deleted successfully',
            confirmButtonColor: '#3085d6'
          })
        })
        .catch(async function (error) {
          await Swal.fire({
            title: error?.response?.data?.message || 'Unable to delete user',
            confirmButtonColor: '#3085d6'
          })
        })
    }
  }

  const getAdminUsers = () => {
    // if(user_data_cookies.role === "admin"){
    //   const data1 = userDetails?.filter((d) => {return d.role === "admin"});
    //   if(data1.length){
    //    return setData(data1);
    //   }
    // } else{
    axios.get('/adminusers').then(async response => {
      if (response.status === 200 && response.data) {
        setData(response.data)
      } else {
        throw new Error(response?.error)
      }
    })
    // }
  }

  useEffect(() => {
    getAdminUsers()
  }, [userDetails])

  const columns = [
    {field: 'id', headerName: 'ID', width: 90},
    {field: 'companyName', headerName: 'Company Name', width: 200},
    {field: 'companyaddress', headerName: 'Company Address', width: 300},
    {
      field: 'companytelephonenumber',
      headerName: 'Company Phone',
      width: 200
    },
    {field: 'fullName', headerName: 'Primary Contact', width: 200},
    {field: 'email', headerName: 'Email Address', width: 300},
    {field: 'role', headerName: 'Role', width: 150},
    {
      field: 'action',
      headerName: 'Action',
      width: 400,
      renderCell: params => {
        return (
          <div className="userListActions">
            <Link to={'/user-view/' + params.row.id}>
              <button className="actionElementButton">
                <VisibilityOutlined className="actionElementIcon" />
                <span className="actionButtonText">View</span>
              </button>
            </Link>
            <Link to={'/user-edit/' + params.row.id}>
              <button className="actionElementButton">
                <EditOutlined className="actionElementIcon" />
                <span className="actionButtonText">Edit</span>
              </button>
            </Link>
            <DeleteOutlined className="userListDelete" onClick={() => handleDelete(params.row.id, params.row.fullName)} />
          </div>
        )
      }
    }
  ]

  return (
    <div className="superAdmins">
      <div className="pageSubHeader">
        <h3 className="pageTableTitle">Administrators</h3>

        {/* {user_data_cookies.role === "admin" ? null : ( */}
        <div className="align-self-end">
          <Link to={'/newUser/admin'}>
            <button className="min-w-[137px]  bg-[#f05865] cursor-pointer text-[white] h-[60px] text-lg font-normal mt-[30px] px-2.5 py-[7px] rounded-xl">
              Add
            </button>
          </Link>
        </div>
        {/* )} */}
        {/* <div className="addButtonContainer">
        <Link to={'/newUser/admin' }>
          <button className="addElementButton">
            <span className="addButtonText">Add</span>
            <AddOutlined className='addElementIcon' />
          </button>
        </Link>
      </div> */}
      </div>
      <DataGrid
        rows={data}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[5]}
        checkboxSelection
        disableSelectionOnClick
        autoHeight={true}
      />
    </div>
  )
}
