import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import useMediaQuery from '@mui/material/useMediaQuery'
import {useTheme} from '@mui/material/styles'

const PopupComponent = props => {
  const {handleClose, open, children, closeBtn, text, backBtn, handleClick} = props
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} sx>
        <div className="flex justify-between h-16 w-full">
          <div className="flex justify-start items-center w-[90%] pl-1">
            {backBtn && (
              <div>
                <button className="text-3xl" onClick={handleClick}>{`<`}</button>
              </div>
            )}
            <div className="text-xl font-manrope pl-[24px] font-semibold">{text ? text : ''}</div>
          </div>
          {closeBtn === true ? (
            <div className="w-[10%]">
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: 'absolute',
                  right: 15,
                  top: 8,
                  textAlign: 'center',
                  color: theme => theme.palette.grey[500],
                  '&:hover': {
                    backgroundColor: 'transparent'
                  },
                  border: '1px solid black',
                  width: '24px !important',
                  height: '25px !important'
                }}
              >
                <CloseIcon
                  sx={{
                    '&:hover': {
                      backgroundColor: 'transparent'
                    },
                    color: 'black',
                    fontSize: '20px'
                  }}
                />
              </IconButton>
            </div>
          ) : (
            ''
          )}
        </div>
        <DialogContent>{children}</DialogContent>
      </Dialog>
    </>
  )
}

export default PopupComponent
