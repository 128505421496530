import PopupComponent from '../Popup/PopupComponent'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import Box from '@mui/material/Box'
import {useEffect, useState} from 'react'
import {styled} from '@mui/material/styles'
import Select from 'react-select'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Cookies from 'universal-cookie'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import axios from '../../helpers/request'
import Swal from 'sweetalert2'
import CircularProgress from '@mui/material/CircularProgress'
import SendIcon from '@mui/icons-material/Send'
import WhatsAppSVG from '../../components/images/logos_whatsapp-icon.svg'

const WhatsAppPopup = props => {
  const {
    popupModel,
    user,
    setPopupModel,
    setAllData,
    archiveNumber,
    phone,
    setPhoneNo,
    setSelectedContact,
    selectedContact,
    currentChannel,
    convId,
    fileStore,
    currentTemplateSent,
    mediaType,
    file,
    loading,
    isSizeBig,
    mediaUrl,
    getChatList,
    handleUnarchive,
    chatsNumber
  } = props
  const cookies = new Cookies()
  const userToken = cookies.get('cs_token')
  const [selectAllContact, setSelectAllContact] = useState([])
  const [countryCode, setCountryCode] = useState('')
  const [channelList, setChannelList] = useState([])
  const [templateData, setTemplateData] = useState('')
  const [channel, setChannel] = useState('')
  const [value, setValue] = useState('1')
  const [isLoadingImage, setIsLoadingImage] = useState(false)

  const getAllContactList = () => {
    axios
      .get(`/all-contacts`)
      .then(res => {
        const allData = res.data.length > 0 && res.data.filter(data => data?.number !== null && data?.number !== '')

        setSelectAllContact(allData)
      })
      .catch(err => {
        console.error('errr', err)
      })
  }

  const getChannelList = () => {
    axios
      .post(`${process.env.REACT_APP_API_BASEURL}/userdetails`, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      })
      .then(res => {
        if (res.data.length > 0) {
          axios.get(`/all-channels/${res.data[0].added_by ? res.data[0].added_by : res.data[0].id}`).then(async response => {
            if (response.status === 200 && response.data) {
              setChannelList(response.data)
            } else {
              throw new Error(response?.error)
            }
          })
        }
      })
      .catch(err => {
        console.log('errr', err)
      })
  }

  const getTemplates = id => {
    axios
      .get(`/wp-templates/${id}`)
      .then(async response => {
        if (response.status === 200 && response.data) {
          setTemplateData(response.data)
        } else {
          throw new Error(response?.error)
        }
      })
      .catch(err => {
        console.log('errr', err)
      })
  }

  useEffect(() => {
    getAllContactList()
    getChannelList()
  }, [])

  /**
   * handle change for contact add
   * @param {*} event
   * @param {*} newValue
   */
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  // custom mui tab
  const StyledTab = styled(Tab)({
    '&.Mui-selected': {
      color: '#2C87DE',
      fontWeight: 'bold'
    },
    textTransform: 'none',
    color: '#4C4448',
    fontWeight: 'bold'
  })

  /**
   * handle close contact function
   */
  const handleCloseContact = () => {
    setPopupModel('')
  }

  /**
   * handle submit contact number
   * @param {*} event
   */
  const handleClickAddNumber = event => {
    event.preventDefault()
    if ((phone !== '' && phone !== countryCode) || (selectedContact !== null && selectedContact !== '')) {
      setTemplateData([])

      if (
        !chatsNumber?.includes(`+${phone}`) &&
        !chatsNumber?.includes(selectedContact.toString()) &&
        !archiveNumber?.includes(`+${phone}`) &&
        !archiveNumber?.includes(selectedContact.toString())
      ) {
        setPopupModel('SelectChannel')
      } else if (archiveNumber?.includes(`+${phone}`) || archiveNumber?.includes(selectedContact.toString())) {
        setPopupModel('SelectArchive')
      } else {
        Swal.fire({
          title: 'Mobile number already exits.',
          confirmButtonColor: '#3085d6'
        })
      }
      /* setButtonPopup(true) */
    } else {
      Swal.fire({
        title: 'Please enter you phone number',
        confirmButtonColor: '#3085d6'
      })
    }
  }

  /**
   * handle submit template function
   * @param {*} id
   */
  const applyTemplate = id => {
    let currentChannelData = channelList.filter(v => v.id == channel)[0]
    let {fromdata, sid, token, serviceId} = currentChannelData

    const fullName = user.fullName
    const selectedTemplate = templateData.filter(temp => temp.sid === id)
    if (selectedTemplate && selectedTemplate[0]) {
      let messageBody = selectedTemplate[0].types['twilio/text'].body
      let variables = selectedTemplate[0].variables
      for (const key in variables) {
        if (variables.hasOwnProperty(key)) {
          messageBody = messageBody.replace(`{{${key}}}`, variables[key])
        }
      }

      setAllData({
        templateSid: id,
        phone: phone ? phone : selectedContact,
        dropdown: selectedContact ? true : false,
        messageBody: messageBody,
        channelId: channel,
        fromdata,
        sid,
        token,
        serviceId,
        companyid: user.added_by ? user.added_by : user.id,
        fullName
      })
      setPopupModel('')
    }
    setPopupModel('')
  }

  /**
   * media api function
   * @param {*} e
   */
  const sendMediaMessage = async e => {
    e.preventDefault()
    setIsLoadingImage(true)

    // /upload-whatsapp-media

    const {sid, token, fromdata} = currentChannel
    const formData = new FormData()
    formData.append('whatsappMedia', fileStore)
    formData.append('contactNumber', convId.split('-sends-')[1])
    formData.append('fromdata', fromdata)
    formData.append('token', token)
    formData.append('sid', sid)
    formData.append('templateSent', 'senderTemplate')
    try {
      await axios.post('/upload-whatsapp-media', formData)
      getChatList()
    } catch (error) {
      console.log(error, 'fileupload')
    }
    setPopupModel('')
    setIsLoadingImage(false)
  }

  return (
    <>
      <div>
        {/* contact select popup start */}
        <PopupComponent
          open={popupModel === 'selectContact'}
          closeBtn={true}
          title={false}
          backBtn={false}
          text=""
          handleClose={handleCloseContact}
        >
          <div>
            <TabContext value={value}>
              <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <TabList onChange={handleChange} aria-label="lab API tabs example">
                  {user?.settings?.enable_contacts === 1 && <StyledTab label="Existing Contact List" value="1" />}
                  <StyledTab label="Add Number" value="2" />
                </TabList>
              </Box>
              {user?.settings?.enable_contacts === 1 && (
                <TabPanel value="1">
                  <div>
                    <div className="pb-2 font-semibold">Select Contact List</div>
                    <div>
                      <Select
                        options={selectAllContact?.map(data => ({
                          label: `${data?.first_name} ${data?.last_name !== null ? data?.last_name : ''}`,
                          value: data?.number
                        }))}
                        className="w-full md:max-w-[550px] md:min-w-[550px] lg:max-w-[550px] lg:min-w-[550px] sm:w-full"
                        onChange={e => setSelectedContact(e.value)}
                      />
                    </div>
                    <div className="w-full flex  justify-end">
                      <button
                        type="button"
                        className="bg-[#f05865] cursor-pointer text-[white] h-[60px] text-lg font-normal mt-[30px] px-2.5 py-[7px] rounded-xl"
                        onClick={e => handleClickAddNumber(e)}
                      >
                        Add Number
                      </button>
                    </div>
                  </div>
                </TabPanel>
              )}
              <TabPanel value="2">
                <div>
                  {/* <label>
                    Add Mobile Number <span className="text-red-700 font-bold">*</span>
                  </label> */}

                  <PhoneInput
                    inputStyle={{
                      width: '100%',
                      height: '50px'
                    }}
                    className="md:max-w-[550px] md:min-w-[550px] lg:max-w-[550px] lg:min-w-[550px] sm:w-full"
                    country={'ie'}
                    value={phone}
                    onChange={(data, country) => {
                      setPhoneNo(data)
                      setCountryCode(country)
                    }}
                  />
                </div>
                <div className="w-full flex justify-end">
                  <button
                    type="button"
                    className="bg-[#f05865] cursor-pointer text-[white] h-[60px] text-lg font-normal mt-[30px] px-2.5 py-[7px] rounded-xl"
                    onClick={e => handleClickAddNumber(e)}
                  >
                    Add Number
                  </button>
                </div>
              </TabPanel>
            </TabContext>
          </div>
        </PopupComponent>
        {/* contact select popup end */}

        {/* channel select popup start */}
        <PopupComponent
          open={popupModel === 'SelectChannel'}
          closeBtn={true}
          title={false}
          backBtn={false}
          text="Send Via which channel/number?"
          handleClose={handleCloseContact}
        >
          <div>
            {channelList.length > 0 &&
              channelList
                .filter(data => data?.type === 'whatsapp')
                .map((data, index) => (
                  <div
                    key={index}
                    className="cursor-pointer"
                    onClick={() => {
                      setChannel(data?.id)

                      setPopupModel('SelectTemplate')
                      getTemplates(data?.id)
                    }}
                  >
                    <div className="">
                      <div className="flex justify-between w-full md:min-w-[550px] lg:max-w-[550px] lg:min-w-[550px] sm:w-full pb-4 cursor-pointer">
                        <div className="font-semibold font-manrope">{data?.label}</div>
                        <div>
                          <img src={WhatsAppSVG} alt="whatsapp-icon" />
                        </div>
                      </div>
                    </div>
                    <div className="border border-[#D8D5D6] rounded-lg bg-[#F9FAFC] flex items-center h-[40px] py-1 px-1 cursor-pointer">
                      {data?.fromdata}
                    </div>
                  </div>
                ))}
          </div>
        </PopupComponent>
        {/* channel select popup end */}

        {/* template select popup start */}
        <PopupComponent
          open={popupModel === 'SelectTemplate'}
          closeBtn={true}
          title={false}
          backBtn={false}
          text="Select a WhatsApp template:"
          handleClose={handleCloseContact}
        >
          <div className="w-full md:min-w-[550px] lg:max-w-[550px] lg:min-w-[550px] sm:w-full">
            {templateData.length > 0 &&
              templateData.map((temp, index) => (
                <div key={index} className="cursor-pointer">
                  <div
                    className="pb-3"
                    onClick={() => {
                      applyTemplate(temp?.sid)
                    }}
                  >
                    <div className="border-transparent rounded-lg flex h-[40px]  items-center pl-2 bg-[#F9FAFC]">
                      <div>
                        <img src={WhatsAppSVG} alt="whatsapp-icon" />
                      </div>
                      <div className="pl-2">{temp?.friendly_name}</div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </PopupComponent>
        {/* template select popup end */}

        {/* upload media file for whatsapp select popup start */}
        <PopupComponent
          open={popupModel === 'SelectUploadFile'}
          closeBtn={true}
          title={false}
          backBtn={false}
          text=""
          handleClose={handleCloseContact}
        >
          <div style={{position: 'relative'}}>
            {mediaType === 'IMG' ? (
              <img src={file} alt="media-url" className={`${isLoadingImage && 'image-media'}`} width={'100%'} />
            ) : mediaType === 'VIDEO' ? (
              <video width="400" controls>
                <source src={file} />
              </video>
            ) : (
              ''
            )}
            {isLoadingImage && (
              <div
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  top: 0
                }}
              >
                <CircularProgress />
              </div>
            )}
          </div>
          <button
            type="button"
            id="button-addon2"
            class="btn-send"
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'center',
              paddingTop: '10px'
            }}
            disabled={isSizeBig === true || !convId || loading}
            onClick={e => {
              sendMediaMessage(e)
            }}
          >
            {' '}
            <SendIcon style={{fontSize: '30px'}} />
          </button>
        </PopupComponent>
        {/* upload media file for whatsapp select popup end */}

        {/*  media file preview for whatsapp select popup start */}
        <PopupComponent
          open={popupModel === 'SelectFilePreview'}
          closeBtn={true}
          title={false}
          backBtn={false}
          text=""
          handleClose={handleCloseContact}
        >
          <div>
            {mediaType === 'IMG' ? (
              <img src={mediaUrl} alt="media-url" width={'100%'} />
            ) : mediaType === 'VIDEO' ? (
              <video width="400" controls>
                <source src={mediaUrl} />
              </video>
            ) : (
              ''
            )}
          </div>
        </PopupComponent>
        {/*  media file preview for whatsapp select popup end */}

        {/*  popup for while adding phone number is already in archive then this popup start */}
        <PopupComponent
          open={popupModel === 'SelectArchive'}
          closeBtn={true}
          title={false}
          backBtn={false}
          text=""
          handleClose={handleCloseContact}
        >
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '20px',
                fontWeight: 'bold',
                padding: '15px'
              }}
            >
              You are added mobile number conversation is archived do you want unarchive?
            </div>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: '15px'}}>
              <button
                className="archiveButton mt-2"
                style={{marginRight: '5px'}}
                onClick={e => {
                  e.preventDefault()
                  handleUnarchive()
                }}
              >
                Yes
              </button>
              <button
                className="sendNowButton mt-2"
                style={{marginLeft: '5px'}}
                onClick={() => {
                  setPopupModel('')
                }}
              >
                No
              </button>
            </div>
          </div>
        </PopupComponent>
        {/*  popup for while adding phone number is already in archive then this popup start */}
      </div>
    </>
  )
}

export default WhatsAppPopup
