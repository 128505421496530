import './overview.css'

import {useState, useEffect} from 'react'
import axios from '../../../helpers/request'

import SMSicon from '../../../components/images/smsicon.svg'
import Emailicon from '../../../components/images/emailicon.svg'

import CountUp from 'react-countup'
import {CircularProgressbarWithChildren} from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import {Grid} from '@mui/material'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
} from 'chart.js'

import {Bar, Line} from 'react-chartjs-2'
import {useAppState} from '../../../state'
import {DateFilterComponent} from '../DateFilter/DateFilterComponent'
import CustomPopup from '../../Popup/CustomPopup'

import Cookies from 'universal-cookie'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Swal from 'sweetalert2'
import CategoryChart from './components/CategoryChart'

ChartJS.register(PointElement, LineElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

let allMonths = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]
let labels = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

export const options = {
  plugins: {
    legend: {
      position: 'bottom'
    }
  },
  responsive: true,
  scales: {
    x: {
      stacked: false
    }
  },
  redraw: true
}

const defaultDataset = {
  labels,
  datasets: [
    {
      label: 'Email',
      data: [],
      backgroundColor: '#52ace9',
      barThickness: 30,
      borderRadius: 5
    },
    {
      label: 'SMS ',
      data: [],
      backgroundColor: '#da524c',
      barThickness: 35,
      borderRadius: 5
    }
  ]
}

const defaultDataset2 = {
  labels: [],
  datasets: [
    {
      label: 'Sent',
      data: [],
      backgroundColor: '#52ace9',
      barThickness: 30,
      borderRadius: 3
    },
    {
      label: 'Fail ',
      data: [],
      backgroundColor: '#da524c',
      barThickness: 30,
      borderRadius: 3
    }
  ]
}

const byDayOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: 'bottom'
    }
  },
  redraw: true
}

const getBarDataSets = data => {
  const counts = Object.entries(data)
  return [
    {
      label: 'Sent',
      data: counts.map(([_, d]) => d.success),
      backgroundColor: '#52ace9',
      barThickness: 12,
      borderRadius: 3
    },
    {
      label: 'Fail',
      data: counts.map(([_, d]) => d.failed),
      backgroundColor: '#da524c',
      barThickness: 12,
      borderRadius: 3
    }
  ]
}

const generateRandomColor = () => {
  let maxVal = 0xffffff // 16777215
  let randomNumber = Math.random() * maxVal
  randomNumber = Math.floor(randomNumber)
  randomNumber = randomNumber.toString(16)
  let randColor = randomNumber.padStart(6, 0)
  return `#${randColor.toUpperCase()}`
}

const reduceCb = (acc, {label, status, counts}) => {
  if (!acc[label]) acc[label] = {success: 0, failed: 0}
  acc[label][status] = counts
  return acc
}

export default function Overview() {
  let defaultSmsEmailstatusRate = {
    sms: {
      success: 0,
      fail: 0
    },
    email: {
      success: 0,
      fail: 0
    }
  }
  const {user: loggedUser} = useAppState()
  const [data, setData] = useState({
    TotalEmails: 0,
    TotalEmailsSuccess: 0,
    TotalEmailsLast7: 0,
    TotalEmailsSuccessLast7: 0,
    TotalMessages: 0,
    TotalFailedLast7: 0,
    TotalFailed: 0,
    TotalSuccessLast7: 0
  })
  const [additionalData, setAdditionalData] = useState({
    // locations: defaultDataset2,
    senders: defaultDataset2,
    // routes: defaultDataset2,
    countries: defaultDataset2,
    // types: defaultDataset2,
    dates: defaultDataset2
  })
  const [smsStatusRateData, setSmsStatusRateData] = useState(defaultSmsEmailstatusRate.sms)
  const [emailStatusRateData, setEmailStatusRateData] = useState(defaultSmsEmailstatusRate.email)
  const [barCartData, setBarCartData] = useState(defaultDataset)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [titleText, setTitleText] = useState('All Time')
  const [isOpenPopup, setIsOpenPopup] = useState(false)
  const [chartDetail, setChartDetail] = useState('')
  const [phone, setPhoneNo] = useState('')
  const [customMappings, setCustomMappings] = useState([])
  const [isAddPhone, setIsAddPhone] = useState(false)
  const [countryCode, setCountryCode] = useState('')
  const cookies = new Cookies()
  const [customFields, setCustomFields] = useState([])

  const getFields = () => {
    axios
      .get('/customFields')
      .then(async response => {
        if (response.data && Array.isArray(response.data)) {
          setCustomFields(response.data.filter(({showOnDashboard}) => showOnDashboard))
        }
      })
      .catch(err => {
        console.log('errr', err)
      })
  }

  useEffect(() => {
    getFields()
  }, [])

  const handleDateSelect = (fromDate, toDate) => {
    setTitleText(fromDate && toDate ? `${new Date(fromDate).toDateString()} - ${new Date(toDate).toDateString()}` : 'All Time')
    getDashboardData()
    getDashboardDataSMS()
    getAllServicesSent() // Pass the dates to getAllServicesSent
    getSmsEmailDeliveryFailureRates() // Pass the dates to getSmsEmailDeliveryFailureRates
  }

  const getDashboardData = () => {
    let url = '/dashboard-analytics-all'
    // if (loggedUser.role !== 'superadmin') {
    //   url = '/dashboard-analytics/' + (loggedUser.added_by ? loggedUser.added_by : loggedUser.id)
    // }
    if (startDate && endDate) {
      url += `?startDate=${startDate}&endDate=${endDate}`
    }
    axios.get(url).then(async response => {
      if (response.status === 200 && response.data) {
        const {
          TotalEmails,
          TotalEmailsSuccess,
          TotalEmailsLast7,
          TotalEmailsSuccessLast7,
          TotalMessages,
          TotalFailedLast7,
          TotalSuccessLast7,
          TotalFailed
        } = response.data
        setData({
          TotalEmails,
          TotalEmailsSuccess,
          TotalEmailsLast7,
          TotalEmailsSuccessLast7,
          TotalMessages,
          TotalFailedLast7,
          TotalSuccessLast7,
          TotalFailed
        })
      } else {
        throw new Error(response?.error)
      }
    })
  }

  const getCustomFieldMappings = () => {
    axios
      .get('/customFields/mappings')
      .then(response => {
        const customMappings = Object.entries(response.data).map(([field, mappings]) => {
          return [
            field,
            mappings.reduce(
              (acc, {id, name}) => {
                acc[id] = name
                return acc
              },
              {Other: 'Others'}
            )
          ]
        })
        setCustomMappings(customMappings)
      })
      .catch(err => {
        console.log('Something went wrong fetching routes and locations')
      })
  }

  const getDashboardDataSMS = () => {
    let url = '/dashboard-analytics-sms-all'
    if (loggedUser.role !== 'superadmin') {
      url = '/dashboard-analytics-sms/' + (loggedUser.added_by ? loggedUser.added_by : loggedUser.id)
    }
    if (startDate && endDate) {
      url += `?startDate=${startDate}&endDate=${endDate}`
    }
    axios.get(url).then(async response => {
      if (response.status === 200 && response.data) {
        const {senders, countries, dates, ...customFieldsData} = response.data
        const uniqueSenders = senders.reduce((acc, {label, counts}) => {
          acc[label] = counts
          return acc
        }, {})
        const uniqueCountries = countries.reduce(reduceCb, {})
        const uniqueDates = dates.reduce(reduceCb, {})
        setAdditionalData({
          ...Object.keys(customFieldsData).reduce((acc, fieldName) => {
            const uniqueLabels = customFieldsData[fieldName].reduce(reduceCb, {})
            const currentMapping = customMappings.filter(([field]) => field === fieldName)[0]
            acc[fieldName] = {
              labels: Object.keys(uniqueLabels).map(location =>
                currentMapping && currentMapping[1][location]
                  ? `${currentMapping[1][location]?.substring(0, 10)}${currentMapping[1][location]?.length > 10 ? '...' : ''}`
                  : location
              ),
              datasets: getBarDataSets(uniqueLabels)
            }
            return acc
          }, {}),
          senders: {
            labels: Object.keys(uniqueSenders),
            datasets: [
              {
                data: Object.values(uniqueSenders),
                backgroundColor: Array(Object.keys(uniqueSenders).length)
                  .fill()
                  .map(() => generateRandomColor()),
                borderWidth: 1
              }
            ]
          },
          countries: {labels: Object.keys(uniqueCountries), datasets: getBarDataSets(uniqueCountries)},
          dates: {
            labels: Object.keys(uniqueDates).map(v => v.substring(0, 10)),
            datasets: [
              {
                label: 'Sent',
                data: Object.entries(uniqueDates).map(([_, d]) => d.success),
                borderColor: '#52ace9'
              },
              {
                label: 'Fail',
                data: Object.entries(uniqueDates).map(([_, d]) => d.failed),
                borderColor: '#da524c'
              }
            ]
          }
        })
      } else {
        throw new Error(response?.error)
      }
    })
  }

  const getAllServicesSent = () => {
    let url = '/dashboard-services-sent-all'
    if (loggedUser.role !== 'superadmin') {
      url = `/dashboard-services-sent/${loggedUser.added_by ? loggedUser.added_by : loggedUser.id}`
      if (startDate && endDate) {
        url += `?startDate=${startDate}&endDate=${endDate}`
      }
    }
    axios.get(url).then(async response => {
      if (response.status === 200 && response.data) {
        let tempLabels = response.data.map(data => allMonths[data.sent_month - 1])
        let sentMonths = [...new Set(response.data.map(data => data.sent_month))]
        let firstIndexMonth = tempLabels[0]
        let lastIndexMonth = tempLabels.length.length > 1 ? tempLabels[tempLabels.length - 1] : 0
        labels = [...new Set(tempLabels)]

        let smsData = response.data.filter(data => data.type === 0)
        let emailData = response.data.filter(data => data.type === 1)

        let smsSentMonth = smsData.map(data => data.sent_month)
        let emailSentMonth = emailData.map(data => data.sent_month)

        let smsCount = smsData.map(data => data.count)
        let emailCount = emailData.map(data => data.count)

        sentMonths.map((month, index) => {
          if (index === 0) {
            if (sentMonths[index] === smsSentMonth[index]) {
              smsCount.unshift(0)
            }
            if (sentMonths[index] === emailSentMonth[index]) {
              emailCount.unshift(0)
            }
          } else {
            if (!smsSentMonth.includes(month)) {
              smsCount.splice(index, 0, 0)
            }
            if (!emailSentMonth.includes(month)) {
              emailCount.splice(index, 0, 0)
            }
          }
        })

        if (firstIndexMonth === lastIndexMonth) {
          sentMonths.push(sentMonths[0])
          labels.push(lastIndexMonth)
        }

        smsData = smsData.map(data => data.count)
        emailData = emailData.map(data => data.count)

        const tempchartData = {
          labels,
          datasets: [
            {
              label: 'Email',
              data: emailData,
              backgroundColor: '#52ace9',
              barThickness: 30,
              borderRadius: 5
            },
            {
              label: 'SMS ',
              data: smsData,
              backgroundColor: '#da524c',
              barThickness: 35,
              borderRadius: 5
            }
          ]
        }
        setBarCartData(tempchartData)
      } else {
        throw new Error(response?.error)
      }
    })
  }

  const getSmsEmailDeliveryFailureRates = () => {
    let smsUrl = '/dashboard-sms-delivery-failure-rate'
    let emailUrl = '/dashboard-email-delivery-failure-rate'

    if (loggedUser.role !== 'superadmin') {
      smsUrl = '/company-dashboard-sms-delivery-failure-rate/' + (loggedUser.added_by ? loggedUser.added_by : loggedUser.id)
      emailUrl = '/company-dashboard-email-delivery-failure-rate/' + (loggedUser.added_by ? loggedUser.added_by : loggedUser.id)
      if (startDate && endDate) {
        smsUrl += `?startDate=${startDate}&endDate=${endDate}`
        emailUrl += `?startDate=${startDate}&endDate=${endDate}`
      }
    }

    // Make parallel API calls
    Promise.all([axios.get(smsUrl), axios.get(emailUrl)])
      .then(([smsResponse, emailResponse]) => {
        if (smsResponse.status === 200 && smsResponse.data && emailResponse.status === 200 && emailResponse.data) {
          // SMS data processing
          let smsData = smsResponse.data
          setSmsStatusRateData({success: smsData.successPercentage, fail: smsData.failPercentage})

          // Email data processing
          let emailData = emailResponse.data
          setEmailStatusRateData({success: emailData.successPercentage, fail: emailData.failPercentage})
        } else {
          throw new Error(smsResponse?.error || emailResponse?.error)
        }
      })
      .catch(error => {
        console.error('Error:', error)
      })
  }
  const closeNumberPopUp = async () => {
    setIsOpenPopup(false)
    const response = await axios.put(process.env.REACT_APP_API_BASEURL + '/update-phone-status', {
      phone: 'false',
      email: loggedUser?.email,
      isAdd: false
    })

    if (response.status === 200) {
      cookies.set('cs_isPopUp', response?.data?.isOpenPopup)
    }
  }

  const handleUpdateNumber = async () => {
    const checkPhone = phone.replace(countryCode, '')
    if (phone !== '' && checkPhone !== '') {
      const response = await axios.put(process.env.REACT_APP_API_BASEURL + '/update-phone-status', {
        phone: phone,
        email: loggedUser?.email,
        isAdd: true
      })
      if (response?.status === 200) {
        cookies.remove('cs_isPopUp')
        setIsAddPhone(false)
        setIsOpenPopup(false)
      }
    } else {
      Swal.fire({
        title: 'Please enter you phone number',
        confirmButtonColor: '#3085d6'
      })
    }
  }

  useEffect(() => {
    getCustomFieldMappings()
    getDashboardData()
    //getDashboardDataWhatsapp()
    getAllServicesSent()
    getSmsEmailDeliveryFailureRates()
    //createWPChannel()
    const popUp = cookies.get('cs_isPopUp')
    if (popUp === 'true') {
      setIsOpenPopup(true)
    }
  }, [])

  useEffect(() => {
    getDashboardDataSMS()
  }, [customMappings])

  const handleChartClick = category => {
    setChartDetail(!chartDetail ? category : '')
  }

  const totalMessagesLast7 = data.TotalFailedLast7 + data.TotalSuccessLast7
  const failedSMSPercent = parseInt((data.TotalFailed / data.TotalMessages) * 100)
  const failedSMSPercent7Days = totalMessagesLast7 ? parseInt((data.TotalFailedLast7 / totalMessagesLast7) * 100) : 0

  return (
    <div>
      <DateFilterComponent onDateSelect={handleDateSelect} setStartDate={setStartDate} setEndDate={setEndDate} />

      <div className="flex flex-col 2xl:grid 2xl:grid-cols-8 md:grid md:grid-cols-4 md:gap-4 w-full items-center md:items-stretch mb-5">
        <div className="flex w-full  flex-col border border-solid rounded-lg p-2 md:mb-0 mb-2 ">
          <div className="flex justify-between">
            <div className="w-full">
              <img className="md:max-w-[35px] max-w-[40px] w-full" src={SMSicon} width="300" alt="overview-icon" />
              <h2 className="text-lg font-bold">SMS</h2>
            </div>
            <div className="w-full flex items-end justify-end">
              <h3 className="text-2xl font-bold">
                <CountUp end={data.TotalMessages} />
              </h3>
            </div>
          </div>
          <div className="w-full">
            <p>Total SMS Sent</p>
          </div>
        </div>
        <div className="flex w-full  flex-col border border-solid rounded-lg p-2 md:mb-0 mb-2 ">
          <div className="flex justify-between">
            <div className="w-full">
              <img className="md:max-w-[35px] max-w-[40px] w-full" src={SMSicon} width="300" alt="overview-icon" />
              <h2 className="text-lg font-bold">SMS</h2>
            </div>
            <div className="w-full flex items-end justify-end">
              <h3 className="text-2xl font-bold">
                <CountUp end={data.TotalFailed} />
              </h3>
            </div>
          </div>
          <div className="w-full">
            <p>SMS Failed</p>
          </div>
        </div>
        <div className="flex w-full  flex-col border border-solid rounded-lg p-2 md:mb-0 mb-2 ">
          <div className="flex justify-between">
            <div className="w-full">
              <img className="md:max-w-[35px] max-w-[40px] w-full" src={SMSicon} width="300" alt="overview-icon" />
              <h2 className="text-lg font-bold">SMS</h2>
            </div>
            <div className="w-full flex items-end justify-end">
              <h3 className="text-2xl font-bold">
                <CountUp end={failedSMSPercent} suffix="%" />
              </h3>
            </div>
          </div>
          <div className="w-full">
            <p>SMS Failed (%)</p>
          </div>
        </div>
        <div className="flex w-full  flex-col border border-solid rounded-lg p-2 md:mb-0 mb-2 ">
          <div className="flex justify-between">
            <div className="w-full">
              <img className="md:max-w-[35px] max-w-[40px] w-full" src={SMSicon} width="300" alt="overview-icon" />
              <h2 className="text-lg font-bold">SMS</h2>
            </div>
            <div className="w-full flex items-end justify-end">
              <h3 className="text-2xl font-bold">
                <CountUp end={failedSMSPercent7Days} suffix="%" />
              </h3>
            </div>
          </div>
          <div className="w-full">
            <p>Failed (Last 7 days)</p>
          </div>
        </div>
        <div className="flex w-full  flex-col border border-solid rounded-lg p-2 md:mb-0 mb-2 ">
          <div className="flex justify-between">
            <div className="w-full">
              <img className="md:max-w-[35px] max-w-[40px] w-full" src={Emailicon} width="300" alt="overview-icon" />
              <h2 className="text-lg font-bold">Email</h2>
            </div>
            <div className="w-full flex items-end justify-end">
              <h3 className="text-2xl font-bold">
                <CountUp end={data.TotalEmails} />
              </h3>
            </div>
          </div>
          <div className="w-full">
            <p>Total Emails Sent</p>
          </div>
        </div>
        <div className="flex w-full  flex-col border border-solid rounded-lg p-2 md:mb-0 mb-2 ">
          <div className="flex justify-between">
            <div className="w-full">
              <img className="md:max-w-[35px] max-w-[40px] w-full" src={Emailicon} width="300" alt="overview-icon" />
              <h2 className="text-lg font-bold">Email</h2>
            </div>
            <div className="w-full flex items-end justify-end">
              <h3 className="text-2xl font-bold">
                <CountUp end={data.TotalEmails - data.TotalEmailsSuccess} />
              </h3>
            </div>
          </div>
          <div className="w-full">
            <p>Emails Failed</p>
          </div>
        </div>
        <div className="flex w-full  flex-col border border-solid rounded-lg p-2 md:mb-0 mb-2 ">
          <div className="flex justify-between">
            <div className="w-full">
              <img className="md:max-w-[35px] max-w-[40px] w-full" src={Emailicon} width="300" alt="overview-icon" />
              <h2 className="text-lg font-bold">Email</h2>
            </div>
            <div className="w-full flex items-end justify-end">
              <h3 className="text-2xl font-bold">
                <CountUp
                  end={data.TotalEmails ? parseInt(((data.TotalEmails - data.TotalEmailsSuccess) / data.TotalEmails) * 100) : 0}
                  suffix="%"
                />
              </h3>
            </div>
          </div>
          <div className="w-full">
            <p>Emails Failed (%)</p>
          </div>
        </div>
        <div className="flex w-full  flex-col border border-solid rounded-lg p-2 md:mb-0 mb-2 ">
          <div className="flex justify-between">
            <div className="w-full">
              <img className="md:max-w-[35px] max-w-[40px] w-full" src={Emailicon} width="300" alt="overview-icon" />
              <h2 className="text-lg font-bold">Email</h2>
            </div>
            <div className="w-full flex items-end justify-end">
              <h3 className="text-2xl font-bold">
                <CountUp
                  end={
                    data.TotalEmailsLast7
                      ? parseInt(((data.TotalEmailsLast7 - data.TotalEmailsSuccessLast7) / data.TotalEmailsLast7) * 100)
                      : 0
                  }
                  suffix="%"
                />
              </h3>
            </div>
          </div>
          <div className="w-full">
            <p>Failed (Last 7 days)</p>
          </div>
        </div>
      </div>

      {!chartDetail ? (
        <>
          <div className="w-full flex flex-wrap justify-between">
            <div className="border border-solid rounded-lg w-full lg:w-[calc(50%-10px)] p-5 mb-1">
              <div className="completion-container">
                <span className="response-block"></span>
                <span className="title">SMS Delivery and Failure Rate ({titleText})</span>
              </div>
              <div className="completion-response-chart mt-2">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} lg={6} className="chart-content flex !flex-col justify-center items-center">
                    <CircularProgressbarWithChildren
                      strokeWidth={5}
                      text={(smsStatusRateData.success || 0) + '%'}
                      styles={{
                        root: {
                          height: '200px',
                          width: 'auto'
                        },
                        path: {
                          stroke: '#27AE60'
                        },
                        trail: {
                          stroke: '#9bedbd'
                        }
                      }}
                      value={smsStatusRateData.success}
                    ></CircularProgressbarWithChildren>
                    <p className="chart-title title">Delivered</p>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={6} className="chart-content flex !flex-col justify-center items-center">
                    <CircularProgressbarWithChildren
                      strokeWidth={5}
                      text={(smsStatusRateData.fail || 0) + '%'}
                      styles={{
                        root: {
                          height: '200px',
                          width: 'auto'
                        },
                        path: {
                          stroke: '#ee5864'
                        },
                        trail: {
                          stroke: '#f8b9bd'
                        }
                      }}
                      value={smsStatusRateData.fail}
                    ></CircularProgressbarWithChildren>
                    <p className="chart-title title">Failed</p>
                  </Grid>
                </Grid>
              </div>
            </div>
            <div className="border border-solid rounded-lg w-full lg:w-[calc(50%-10px)] p-5 mb-1">
              <div className="completion-container">
                <span className="response-block"></span>
                <span className="title">Email Delivery and Failure Rate ({titleText})</span>
              </div>
              <div className="completion-response-chart mt-2">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} lg={6} className="chart-content flex !flex-col justify-center items-center">
                    <CircularProgressbarWithChildren
                      strokeWidth={5}
                      text={(emailStatusRateData.success || 0) + '%'}
                      styles={{
                        root: {
                          height: '200px',
                          width: 'auto'
                        },
                        path: {
                          stroke: '#27AE60'
                        },
                        trail: {
                          stroke: '#9bedbd'
                        }
                      }}
                      value={emailStatusRateData.success}
                    ></CircularProgressbarWithChildren>
                    <p className="chart-title title">Delivered</p>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={6} className="chart-content flex !flex-col justify-center items-center">
                    <CircularProgressbarWithChildren
                      strokeWidth={5}
                      text={(emailStatusRateData.fail || 0) + '%'}
                      styles={{
                        root: {
                          height: '200px',
                          width: 'auto'
                        },
                        path: {
                          stroke: '#ee5864'
                        },
                        trail: {
                          stroke: '#f8b9bd'
                        }
                      }}
                      value={emailStatusRateData.fail}
                    ></CircularProgressbarWithChildren>
                    <p className="chart-title title">Failed</p>
                  </Grid>
                </Grid>
              </div>
            </div>

            <div className="dashboardboxsmallmed border border-solid rounded-lg w-full lg:w-[calc(50%-10px)] p-5 mb-1">
              <div className="completion-container">
                <span className="response-block"></span>
                <span className="title">Campaign Send Rates ({titleText}) </span>
              </div>
              <Bar className="bar-chart completion-response-chart" options={options} data={barCartData} redraw={false} />
            </div>

            <div className="dashboardboxsmallmed border border-solid rounded-lg w-full lg:w-[calc(50%-10px)] p-5 mb-1">
              <div className="completion-container">
                <span className="response-block"></span>
                <span className="title">Sent and Failed by Day </span>
              </div>
              <Line
                className="bar-chart completion-response-chart"
                options={{...byDayOptions, onClick: () => handleChartClick('date')}}
                data={additionalData.dates}
              />
            </div>

            <div className="border border-solid rounded-lg w-full lg:w-[calc(50%-10px)] p-5 mb-1">
              <CategoryChart category={'countries'} data={additionalData['countries']} handleChartClick={handleChartClick} />
              {customFields.map(({name, displayName}) =>
                additionalData[name] ? (
                  <CategoryChart
                    category={name}
                    displayName={displayName}
                    data={additionalData[name]}
                    handleChartClick={handleChartClick}
                  />
                ) : null
              )}
            </div>
            <div className="border border-solid rounded-lg w-full lg:w-[calc(50%-10px)] p-5 mb-1">
              <CategoryChart
                category={'senders'}
                data={additionalData['senders']}
                handleChartClick={handleChartClick}
                type={'Pie'}
              />
            </div>
          </div>
        </>
      ) : (
        <Grid container rowSpacing={1} spacing={{xs: 2, md: 4}} columns={{xs: 4}}>
          <CategoryChart
            type={chartDetail === 'senders' ? 'Pie' : 'Bar'}
            fullView={true}
            category={chartDetail}
            data={additionalData[chartDetail]}
            handleChartClick={handleChartClick}
          />
        </Grid>
      )}

      <CustomPopup trigger={isOpenPopup} setTrigger={() => closeNumberPopUp()}>
        <div className="phone-msg">
          <div className="msg-text">Attention Users: Enable Two-Factor Authentication (2FA) Today!</div>
          <div className="msg-sub-text">
            Protect your account with an extra layer of security! Our Two-Factor Authentication (2FA) supports mobile numbers and
            is incredibly easy to use. Update your mobile number now to keep your account safe and secure.
          </div>
          <div className="main-phone">
            <button className="btn-add-phone btn btn-primary text-white br-10" onClick={() => setIsAddPhone(!isAddPhone)}>
              Add Phone number
            </button>
          </div>
        </div>
      </CustomPopup>
      <CustomPopup trigger={isAddPhone} setTrigger={() => setIsAddPhone(!isAddPhone)}>
        <div>
          <div className="form-main">
            <form>
              <div className="phone-form">
                <label>Phone No</label>
                <PhoneInput
                  inputStyle={{
                    width: '600px',
                    height: '50px'
                  }}
                  country={'ie'}
                  value={phone}
                  onChange={(data, country) => {
                    setPhoneNo(data)
                    setCountryCode(country?.dialCode)
                  }}
                />
                <div className="phone-btn-add">
                  <button className="btn btn-primary text-white br-10" type="button" onClick={() => handleUpdateNumber()}>
                    Add
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </CustomPopup>
    </div>
  )
}
