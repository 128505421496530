import {useState, useEffect} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {SecurityOutlined} from '@material-ui/icons'
import axios from 'axios'
import PasswordChecklist from 'react-password-checklist'
import Swal from 'sweetalert2'

import logo from '../images/ProntobyKaptea.svg'
import graphic from '../images/createPassword.svg'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import cross from '../images/tabler_x.png'
import check from '../images/check.png'
import './passwordreset.css'

export default function PasswordCreate() {
  // const location = useLocation();
  const history = useHistory()

  const location = useLocation()
  const {search} = location
  const searchParams = new URLSearchParams(search)
  const token = searchParams && searchParams.get('token') ? searchParams.get('token').replace(/\s/g, '+') : ''

  if (!token) {
    Swal.fire({title: 'Invalid url', confirmButtonColor: '#3085d6'})
    history.push('/')
  }

  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [updating, setUpdating] = useState(false)
  const [eyeHide, setEyeHide] = useState(false)
  const [eyeType, setEyeType] = useState(false)

  const isValidData = () => {
    let isValid = true
    let message = ''

    if (newPassword === '' || confirmPassword === '') {
      isValid = false
      message = 'All fields are mandatory'
    } else if (newPassword !== confirmPassword) {
      isValid = false
      message = 'Password and Confirm Password are not matched'
    }

    return {isValid, message}
  }

  const resetPassword = event => {
    event.preventDefault()
    const {isValid, message} = isValidData()
    if (isValid) {
      setUpdating(true)
      axios
        .post(process.env.REACT_APP_API_BASEURL + '/create-password', {token: token, password: confirmPassword})
        .then(function (response) {
          setUpdating(false)
          Swal.fire({title: 'Password created successfully', confirmButtonColor: '#3085d6'})
          history.replace('/')
        })
        .catch(function (error) {
          setUpdating(false)
          Swal.fire({title: 'Unable to create password', confirmButtonColor: '#3085d6'})
        })
    } else {
      Swal.fire({title: message, confirmButtonColor: '#3085d6'})
    }
  }

  return (
    <div className="h-screen w-full lg:p-9 md:p-6 p-4">
      <div className="h-full flex justify-around border border-solid rounded-xl  border-gray-300 md:divide-x md:divide-gray-300 ">
        <div className="hidden md:block justify-center items-center  lg:w-1/2 md:w-1/2">
          <div className="h-full p-5 flex justify-center items-center">
            <img src={graphic} alt="" className="h-auto max-w-full" />
          </div>
        </div>

        <div className="flex justify-center items-center flex-col lg:w-1/2 md:w-1/2">
          <div className="flex flex-col">
            <div className="h-full w-full flex justify-center items-center lg:pb-[20px] lg:pt-[20px] md:pb-[15px] md:pt-[15px] pb-[10px] bt-[10px]">
              <img src={logo} alt="pronto" className="h-full w-full max-w-[186px]" />
            </div>
            <div className="flex justify-center items-center font-semibold text-3xl font-manrope">Create Password</div>
          </div>
          <div className="flex flex-col w-full lg:px-16 md:px-4 pt-6 pb-2 px-4">
            <form className="">
              <div className="flex flex-col pb-2 w-full">
                <label className="font-semibold text-lg mb-4 font-manrope">Password</label>
                <div className="relative">
                  <input
                    type={`${eyeHide === false ? 'password' : 'text'}`}
                    onChange={e => {
                      setNewPassword(e.target.value)
                    }}
                    className="input-field-cls"
                    placeholder="Enter a password for the user"
                  />
                  {eyeHide === true ? (
                    <RemoveRedEyeIcon
                      className="absolute top-[10px] bottom-0 right-0 flex items-center justify-center pr-2"
                      onClick={() => {
                        setEyeHide(!eyeHide)
                      }}
                    />
                  ) : (
                    <VisibilityOffIcon
                      className="absolute top-[10px] bottom-0 right-0 flex items-center justify-center pr-2"
                      onClick={() => {
                        setEyeHide(!eyeHide)
                      }}
                    />
                  )}
                </div>
              </div>

              <div className="flex flex-col w-full">
                <label className="font-semibold text-lg mb-4 font-manrope">Confirm Password</label>
                <div className="relative">
                  <input
                    type={`${eyeType === false ? 'password' : 'text'}`}
                    onChange={e => {
                      setConfirmPassword(e.target.value)
                    }}
                    className="input-field-cls"
                    placeholder="Confirm the password"
                  />
                  {eyeType === true ? (
                    <RemoveRedEyeIcon
                      className="absolute top-[10px] bottom-0 right-0 flex items-center justify-center pr-2"
                      onClick={() => {
                        setEyeType(!eyeType)
                      }}
                    />
                  ) : (
                    <VisibilityOffIcon
                      className="absolute top-[10px] bottom-0 right-0 flex items-center justify-center pr-2"
                      onClick={() => {
                        setEyeType(!eyeType)
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="flex flex-col w-full py-5">
                <PasswordChecklist
                  rules={['minLength', 'specialChar', 'number', 'capital', 'match']}
                  minLength={8}
                  value={newPassword}
                  valueAgain={confirmPassword}
                  onChange={isValid => {}}
                  iconComponents={{
                    ValidIcon: <img src={check} alt="check" className="w-[20px] h-[20px] text-green-500 mt-[6px]" />,
                    InvalidIcon: <img src={cross} alt="cross" className="w-[20px] h-[20px]" />
                  }}
                />
              </div>
              <button
                disabled={updating}
                onClick={resetPassword}
                className="w-full rounded-lg bg-[#f05865] border-none cursor-pointer py-2 px-4 text-white h-[60px] text-lg font-manrope font-medium"
              >
                Create Now
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
