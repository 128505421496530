import moment from 'moment'

// Get events of time - 5 min ago, Today, yesterday, Monday, Dec 05 2023
export const getTimeDetails = timestamp => {
  const currentDate = new Date()
  const date = new Date(timestamp)

  // Time difference in milliseconds
  const timeDiff = currentDate.getTime() - date.getTime()
  const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24))
  const hoursDiff = Math.floor(timeDiff / (1000 * 60 * 60))
  const minutesDiff = Math.floor(timeDiff / (1000 * 60))

  if (daysDiff <= 0) {
    if (minutesDiff < 60) {
      return minutesDiff <= 1 ? `${moment(new Date()).format('hh:mm A')}` : `${moment(new Date()).format('hh:mm A')}`
    } else if (hoursDiff <= 15) {
      return hoursDiff === 1 ? `${moment(new Date()).format('hh:mm A')}` : `${moment(new Date()).format('hh:mm A')}`
    } else {
      return 'Today'
    }
  } else if (daysDiff === 1) {
    return 'Yesterday'
  } else if (daysDiff < 7) {
    const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    const dayOfWeek = weekdays[date.getDay()]
    return dayOfWeek
  } else {
    // More than a week ago, return date in "Mon DD YYYY" format
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    const month = months[date.getMonth()]
    const day = date.getDate().toString().padStart(2, '0')
    const year = date.getFullYear()
    return `${month} ${day} ${year}`
  }
}
