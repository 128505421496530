import {ListItemButton, ListItemIcon, ListItemText, List, Collapse} from '@mui/material'
import {Link} from 'react-router-dom'

import DownArrow from '../../components/images/Vector.svg'

const SidebarMenu = props => {
  const {route, index, open, menuId, setMenuId, setOpen, currentPath, handleToggleClose, mobileView = false} = props
  console.log(route, 'route')

  const handleClick = (id, e) => {
    setMenuId(id === menuId ? '' : id)
    setOpen(true)
  }

  return (
    <>
      <div className="text-right pb-[20px]">
        {route?.items?.length > 0 ? (
          <>
            <ListItemButton
              onClick={e => {
                handleClick(route?.id, e)
              }}
              disableRipple
              sx={{
                minHeight: 40,

                '&:hover': {
                  background: '#ef5864',
                  borderRadius: '12px !important'
                },
                background: route?.id === menuId ? '#ef5864 !important' : 'transparent',
                display: 'flex !important',
                paddingLeft: {lg: '8px !important', md: '8px !important', sm: '30px !important', xs: '30px !important'},
                paddingRight: {lg: '20px !important', md: '20px !important', sm: '30px !important', xs: '30px !important'},
                marginLeft: {lg: '12px !important', md: '12px !important', sm: '25px !important', xs: '25px !important'},
                marginRight: {lg: '12px !important', md: '12px !important', sm: '25px !important', xs: '25px !important'},
                borderRadius: '12px !important'
              }}
              id={route?.id}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: 'auto',
                  display: 'flex',
                  justifyContent: 'center',
                  paddingRight: '10px',
                  '&:hover': {
                    background: 'none'
                  },
                  filter: route?.id === menuId ? 'brightness(0) invert(1)' : 'none'
                }}
                id="subMenuIcon"
              >
                {route?.icon}
              </ListItemIcon>
              {open && (
                <ListItemText
                  sx={{
                    color: route?.id === menuId ? 'white' : '#62585D',
                    '&:hover': {
                      background: 'none'
                    }
                  }}
                  primary={route.title}
                  id="subMenuText"
                />
              )}
              {open &&
                (route?.id === menuId ? (
                  <>
                    <img
                      src={DownArrow}
                      alt="down-up"
                      id="iconImg"
                      style={{
                        filter: route?.id === menuId ? 'brightness(0) invert(1)' : 'none'
                      }}
                    />
                  </>
                ) : (
                  <>
                    <img
                      src={DownArrow}
                      alt="down-arrow"
                      id="iconImg"
                      className={`origin rotate-180 menu-filter`}
                      /* style={{
                        filter: route?.id === menuId ? 'brightness(0) invert(1)' : 'none'
                      }} */
                    />
                  </>
                ))}
            </ListItemButton>
            {open && (
              <Collapse in={route?.id === menuId} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {route?.items?.length > 0 &&
                    route?.items?.map((subItems, index) => (
                      <Link
                        to={subItems?.path}
                        key={subItems?.title}
                        id={subItems?.id}
                        onClick={() => {
                          mobileView === true && handleToggleClose()
                        }}
                        className={`flex  border rounded-xl border-transparent ${currentPath === subItems?.path ? 'bg-[#f0edee] ' : 'bg-transparent'}  pl-[50px] subMenuList lg:mx-3 md:mx-3 mx-[28px] my-2`}
                      >
                        <ListItemButton
                          disableRipple
                          sx={{
                            minHeight: 40,
                            px: 2.5,
                            '&:hover': {
                              background: 'none'
                            },
                            padding: '0px !important'
                          }}
                        >
                          {open && (
                            <ListItemText
                              sx={{
                                color: '#62585D',
                                '&:hover': {
                                  background: 'none'
                                }
                              }}
                              primary={subItems.title}
                            />
                          )}
                        </ListItemButton>
                      </Link>
                    ))}
                  {/* <ListItemButton sx={{pl: 4}}>
                  <ListItemText primary="Starred" />
                </ListItemButton> */}
                </List>
              </Collapse>
            )}
          </>
        ) : (
          <>
            <Link
              to={route?.path}
              key={route?.title}
              id={route?.id}
              className={`flex lg:pl-[12px] lg:pr-[10px] md:pl-[12px] md:pr-[20px] pl-[30px] pr-[30px] border rounded-xl border-transparent ${currentPath === route?.path ? 'bg-[#ef5864] sidebar-menu-active ' : 'sidebar-menu'} lg:mx-2 md:mx-2 mx-[28px] `}
              onClick={() => {
                setMenuId('')
                mobileView === true && handleToggleClose()
              }}
            >
              <ListItemButton
                disableRipple
                sx={{
                  minHeight: 40,
                  px: 2.5,
                  '&:hover': {
                    background: 'none'
                  },
                  padding: '0px !important'
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: 'auto',
                    display: 'flex',
                    justifyContent: 'center',
                    paddingRight: '10px',
                    '&:hover': {
                      background: 'none'
                    },
                    color: currentPath === route?.path ? 'white' : '#62585D',
                    filter: currentPath === route?.path ? 'brightness(0) invert(1)' : 'none'
                  }}
                  id="menuIcon"
                >
                  {route?.icon}
                </ListItemIcon>
                {open && (
                  <ListItemText
                    sx={{
                      color: currentPath === route?.path ? 'white' : '#62585D',
                      '&:hover': {
                        background: 'none'
                      },
                      fontFamily: 'Poppins,sans-serif'
                    }}
                    primary={route.title}
                    id="menuText"
                  />
                )}
              </ListItemButton>
            </Link>
          </>
        )}
      </div>
    </>
  )
}

export default SidebarMenu
