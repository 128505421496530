import './SurveyAnalyticsDetail.css'
import {useState, useEffect} from 'react'
import {useParams, Link} from 'react-router-dom'
import axios from '../../../helpers/request'
import moment from 'moment'
import {Breadcrumbs, Typography} from '@material-ui/core'
import DetailsTable from './DetailsTable'

export default function SurveyAnalyticsDetail() {
  const {campaignId} = useParams()
  const [campaign, setCampaign] = useState({})

  useEffect(() => {
    axios.get(`/surveys/analytics/${campaignId}`).then(async response => {
      if (response.status === 200 && response.data.success && response.data.data.length) {
        setCampaign(response.data.data[0])
      } else {
        throw new Error(response?.error)
      }
    })
  }, [])

  return (
    <div className="campaigns">
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/analytic-survey">Analytics</Link>
        <Typography color="text.primary">Campaign Details</Typography>
      </Breadcrumbs>

      <div className="campaigndetailscontainer">
        <div className="w-100">
          <div className="d-flex">
            <div className="campaignItem">
              <label>Campaign Name</label>
              <span className="dataDescriptionSM">{campaign.name}</span>
            </div>
            <div className="campaignItem">
              <label>Sent By</label>
              <span className="dataDescriptionSM">{campaign.sentby}</span>
            </div>
            <div className="campaignItem">
              <label>Date Sent</label>
              <span className="dataDescriptionSM">
                {campaign.created_at ? moment(campaign.created_at).format('DD/MM/YYYY kk:mm') : ''}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="userTables">
        <div className="userTablesTop">
          <DetailsTable campaignid={campaign.id} />
        </div>
      </div>
    </div>
  )
}
