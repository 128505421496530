import React from 'react'
import axios from '../../../helpers/request'
import './SurveyAnalyticsDetail.css'
import {DataGrid, GridToolbarExport} from '@material-ui/data-grid'
import {useState, useEffect} from 'react'

function CustomToolbar() {
  return (
    <span className="exportbutton">
      <GridToolbarExport />
    </span>
  )
}

export default function ConversationsTable({campaignid}) {
  const [pageSize, setPageSize] = useState(25)
  const [pageNumber, setPageNumber] = useState(1)
  const [data, setData] = useState({data: [], total: 0})

  const getSurveySents = campaignid => {
    setData({data: [], total: 0})
    axios.get(`/surveys/contact-details/${campaignid}`, {params: {pageNumber, pageSize}}).then(async response => {
      if (response.status === 200 && response.data) {
        setData(response.data)
      } else {
        throw new Error(response?.error)
      }
    })
  }

  useEffect(() => {
    if (campaignid) {
      getSurveySents(campaignid)
    }
  }, [campaignid, pageSize, pageNumber])

  const columns = [
    {field: 'id', headerName: 'ID', width: 50, sortable: false},
    {field: 'number', headerName: 'Contact', width: 150, sortable: false},
    {field: 'status', headerName: 'Status', width: 120, sortable: false},
    {
      field: 'answers',
      headerName: 'Answers',
      flex: 1,
      minWidth: 300,
      sortable: false,
      renderCell: params => {
        try {
          return Object.entries(JSON.parse(params.row.answers))
            .map(([key, value]) => `${key}: ${value}`)
            .join(', ')
        } catch (error) {
          return ''
        }
      }
    }
  ]

  return (
    <div className="sentsTable">
      <div className="pageSubHeader">
        <h3 className="pageTableTitle">Survey Contacts</h3>
      </div>
      <DataGrid
        columns={columns}
        rows={data.data}
        rowCount={data.total}
        pageSize={pageSize}
        rowsPerPageOptions={[10, 25, 50]}
        onPageSizeChange={newPageSize => setPageSize(newPageSize)}
        onPageChange={newPage => setPageNumber(newPage + 1)}
        pagination
        paginationMode="server"
        checkboxSelection
        disableSelectionOnClick
        autoHeight={true}
        components={{
          Toolbar: CustomToolbar
        }}
      />
    </div>
  )
}
