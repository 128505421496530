import './CustomFields.css'
import {DataGrid} from '@material-ui/data-grid'
import {DeleteOutlined, EditOutlined} from '@material-ui/icons'
import {useState, useEffect} from 'react'
import axios from '../../../helpers/request'
import Swal from 'sweetalert2'
import {Link} from 'react-router-dom'

export default function CustomFields() {
  const [data, setData] = useState([])

  const getFields = () => {
    axios
      .get('/customFields')
      .then(async response => {
        if (response.data && Array.isArray(response.data)) {
          setData(response.data)
        }
      })
      .catch(err => {
        console.log('errr', err)
      })
  }

  useEffect(() => {
    getFields()
  }, [])

  const handleDelete = async (id, label) => {
    const result = await Swal.fire({
      title: `Are you sure you want to delete ${label}`,
      text: 'Deleting a field will delete all its data for analytics',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    })

    if (result.isConfirmed) {
      axios
        .delete('/customFields/' + id)
        .then(async function () {
          getFields()
          await Swal.fire({title: 'Field deleted successfully', confirmButtonColor: '#3085d6'})
        })
        .catch(async function (error) {
          await Swal.fire({title: error?.response?.data?.message || 'Unable to delete Field', confirmButtonColor: '#3085d6'})
        })
    }
  }

  const columns = [
    {field: 'id', flex: 0.2, headerName: 'ID', width: 50},
    {field: 'name', flex: 1, headerName: 'Key', width: 200},
    {field: 'displayName', flex: 1, headerName: 'Display Name', width: 200},
    {
      field: 'action',
      flex: 1,
      headerName: 'Action',
      width: 400,
      renderCell: params => {
        return (
          <div className="userListActions">
            <Link to={'/custom-field-edit/' + params.row.id}>
              <button className="actionElementButton">
                <EditOutlined className="actionElementIcon" />
                <span className="actionButtonText">Edit</span>
              </button>
            </Link>
            <DeleteOutlined className="userListDelete" onClick={() => handleDelete(params.row.id, params.row.displayName)} />
          </div>
        )
      }
    }
  ]

  return (
    <div>
      <div className="pageSubHeader flex-column">
        <div className="align-self-end">
          <Link to="/new-custom-field">
            <button className="min-w-[137px] bg-[#f05865] cursor-pointer text-[white] h-[60px] text-lg font-normal mt-[30px] px-2.5 py-[7px] rounded-xl">
              Add
            </button>
          </Link>
        </div>
      </div>
      <DataGrid
        rows={data}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[5]}
        checkboxSelection
        disableSelectionOnClick
        autoHeight={true}
      />
    </div>
  )
}
