import './AnalyticsSurvey.css'
import {VisibilityOutlined} from '@material-ui/icons'
import {DataGrid} from '@material-ui/data-grid'
import {useState, useEffect} from 'react'
import axios from '../../../helpers/request'
import {Link} from 'react-router-dom'

import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import {useAppState} from '../../../state'
import moment from 'moment'

function TabPanel(props) {
  const {children, value, index, ...other} = props
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

export default function Analytics() {
  const {user: loggedUser, setIsLoading} = useAppState()
  const [data, setData] = useState({data: [], total: 0})
  const [pageSize, setPageSize] = useState(25)
  const [pageNumber, setPageNumber] = useState(1)

  const getAnalytics = () => {
    setData({
      data: [],
      total: 0
    })
    setIsLoading(true)
    axios
      .get('/surveys/analytics', {
        params: {pageNumber, pageSize, companyId: loggedUser.added_by ? loggedUser.added_by : loggedUser.id}
      })
      .then(async response => {
        setIsLoading(false)
        if (response.data?.data?.length) {
          setData(response.data)
        }
      })
      .catch(async function (error) {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    getAnalytics()
  }, [pageSize, pageNumber])

  const columns = [
    {field: 'id', headerName: 'ID', width: 80, flex: 0.2, sortable: false},
    {field: 'name', headerName: 'Survey Name', flex: 0.3, minWidth: 150, sortable: false},
    {
      field: 'created_at',
      headerName: 'Date Sent',
      flex: 0.3,
      minWidth: 170,
      type: 'dateTime',
      valueGetter: ({value}) => value && moment(value).format('DD/MM/YYYY kk:mm')
    },
    {field: 'sentby', headerName: 'Sent By', flex: 0.3, minWidth: 140, sortable: false},
    {field: 'totalContacts', headerName: 'Total', flex: 0.3, minWidth: 150, sortable: false},
    {
      field: 'action',
      headerName: 'Action',
      minWidth: 250,
      flex: 0.3,
      sortable: false,
      renderCell: params => {
        return (
          <div className="userListActions">
            <Link to={'/survey-analytics-detail/' + params.row.id}>
              <button className="actionElementButton">
                <VisibilityOutlined className="actionElementIcon" />
                <span className="actionButtonText">View</span>
              </button>
            </Link>
          </div>
        )
      }
    }
  ]

  return (
    <div>
      <DataGrid
        columns={columns}
        rows={data.data}
        rowCount={data.total}
        pageSize={pageSize}
        rowsPerPageOptions={[10, 25, 50]}
        onPageSizeChange={newPageSize => setPageSize(newPageSize)}
        onPageChange={newPage => setPageNumber(newPage + 1)}
        disableSelectionOnClick
        paginationMode="server"
        autoHeight={true}
      />
    </div>
  )
}
