import './importcontacts.css'

import React, {useState, useEffect} from 'react'
import {Grid, TextField} from '@mui/material'

import Swal from 'sweetalert2'

export default function CopyPasteFile({customFieldsObj, ...props}) {
  const [csvText, setCsvText] = useState([])

  const checkMatchedHeader = header => {
    let tempHeader = header.toLowerCase().replaceAll(' ', '')

    if (customFieldsObj[tempHeader]) {
      return tempHeader
    }
    return header
  }

  const csvFileToArray = string => {
    let csvRows = []
    let array = []
    let csvHeader = []
    let columnArray = []
    let checkHeader = string.split('\n').filter(function (el) {
      return el != null && el != ''
    })

    if (checkHeader.length < 2) {
      Swal.fire({title: 'Min two rows required, one for column name and second for data', confirmButtonColor: '#3085d6'})
    } else {
      let isCommaSeprated = checkHeader[0].includes(',') && checkHeader[0].includes('"')
      if (isCommaSeprated) {
        csvHeader = string
          .slice(0, string.indexOf('\n'))
          .split(',')
          .filter(function (el) {
            return el != null && el != ''
          })
        csvRows = string
          .slice(string.indexOf('\n') + 1)
          .split('\n')
          .filter(function (el) {
            return el != null && el != ''
          })

        array = csvRows.map(i => {
          const values = i.split(',')
          const obj = csvHeader.reduce((object, header, index) => {
            header = checkMatchedHeader(header)
            object[header.trim()] = values[index].trim()
            return object
          }, {})
          return obj
        })
      } else {
        columnArray = string.split('\n')
        columnArray = columnArray.filter(function (el) {
          return el != null && el != ''
        })

        csvHeader = columnArray[0].split('\t')
        csvHeader = csvHeader.filter(function (el) {
          return el != null && el != ''
        })

        columnArray.shift()
        columnArray = columnArray.filter(function (el) {
          return el != null && el != ''
        })

        csvRows = columnArray.map(item => {
          let itemString = item.split('\t')
          if (itemString !== '') {
            return itemString
          }
        })
        csvHeader = csvHeader.filter(function (el) {
          return el != null
        })
        csvRows = csvRows.filter(function (el) {
          return el != null
        })

        array = csvRows.map(i => {
          const obj = csvHeader.reduce((object, header, index) => {
            header = checkMatchedHeader(header)
            object[header.trim()] = i[index].trim()
            return object
          }, {})
          return obj
        })
      }

      props.setBrowseFileData(array)
      props.continueHandler()
    }
  }

  const handleOnSubmit = e => {
    e.preventDefault()
    if (csvText.length) {
      csvFileToArray(csvText)
    } else {
      Swal.fire({title: 'Enter some csv file text to import contacts', confirmButtonColor: '#3085d6'})
    }
  }

  useEffect(() => {
    if (props.componentCalledSource !== 'contactList') {
      props.setUpdateModalSize(false)
    }
  }, [])

  return (
    <>
      <span className="import-contact-header">Copy and paste your contacts</span>

      <Grid container className="mt-20">
        <Grid item xs={12} md={12} lg={12}>
          <TextField
            placeholder="Example:
                        First Name, Last Name, Email, Phone Number
                        Amelia, Breiner, amelia@pottedplanter.com, +1 (784)-784-784
                        ..."
            multiline
            rows={10}
            fullWidth
            defaultValue={csvText}
            onChange={e => setCsvText(e.target.value)}
          />
        </Grid>
      </Grid>

      <button onClick={e => handleOnSubmit(e)} className="btn btn-primary text-white br-10 importsubmit">
        Continue
      </button>

      <button onClick={() => props.handleBackClick()} className="btn btn-white-red br-10 importsubmit ml-20">
        Back
      </button>
    </>
  )
}
